export const GetLanguage = () => {
    return localStorage.getItem("ita-sc-lang");
};
export const GetNumLanguage = () => {
    switch (localStorage.getItem("ita-sc-lang")) {
        case 'hu': return 0;
        case 'en': return 1;
        case 'sk': return 2;
    
        default:
            return 1;
    }
};