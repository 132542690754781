import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { IoAddCircleSharp } from 'react-icons/io5';
import Sidebar from '../Sidebar/Sidebar';

import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Request from '../../Services/Documents/DocHandler';

export const GetDocCategory = (numcat) => {
    switch(numcat) {
        case 1: return "Dokumentum"
        case 2: return "Match-making adatbázis"
        case 3: return "Prezentáció"
    }
}

const columns = [
    {
        name: '#',
        selector: row => Number(row.id),
        sortable: true,
        maxWidth: "20px"
    },
    {
        name: 'Cím',
        selector: row => row.title,
        sortable: true
    },
    {
        name: 'Típus',
        selector: row => row.category,
        cell: row => GetDocCategory(Number(row.category)),
        sortable: true
    },
    {
        name: 'Nyelv',
        selector: row => row.lang,
        cell: row => row.lang.toUpperCase(),
        sortable: true
    },
    {
        name: 'Fájlok száma',
        selector: row => Number(row.file_count),
        cell: row => row.file_count,
        sortable: true
    },
];


const Documents = () => {
    const DetailedNews = ({data}) => {
        const Remove = () => {
            if(window.confirm(`Biztos, hogy törli a kiválasztott dokumentumot? (#${data.id})`)) {
                
                toast.promise(Request('GET', 'docs/delete&id=' + data.id), {
                    loading: 'Dokumentum törlése..',
                    success: (data) =>  {
                        Request('GET', 'docs/get').then((r) => {
                            setDocs(r);
                        })
                        return `Dokumentum eltávolítva.`
                    },
                    error: 'Nem sikerült eltávolítani a kijelölt dokumentumot.',
                })
            }
        };
        const previewUrl = (url = data.url) => {
            switch(Number(data.category)) {
                case 1: return `/docs/${url}`;
                case 2: return `/match-making/${url}`;
                case 3: return `/presentations/${url}`;
                default: return `/docs/${url}`;
            }
        }
        return (
            <div className="news__detail">
                <Link to={previewUrl()} target={"_blank"} className="news-btn bg-primary">Előnézet</Link>
                <Link to={`edit/${data.id}`} className="news-btn bg-warning">Szerkesztés</Link>
                
                <button onClick={Remove} className="btn bg-danger news-btn">Törlés</button>
            </div>
        );
    };
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        Request('GET', 'docs/get').then((r) => {
            setDocs(r);
        })
        return () => {
            setDocs([]);
        }
    }, [])

    return (
        <div className='dashboard__page'>
            <Sidebar />
            <div className="dashboard__content" id="hirek">
                <Toaster />
                <h1>Dokumentumok</h1>
                <div className="card">
                    <div className="card__head">
                        <h3>Dokumentumok</h3>
                    </div>
                    <div className="card__body">
                        <DataTable data={docs} columns={columns} expandableRows expandOnRowClicked expandableRowsComponent={DetailedNews} pagination highlightOnHover paginationRowsPerPageOptions={[20,30,50]} />

                        <Link to="create" className='btn bg-primary mb-2'><IoAddCircleSharp className='btn-icon' /> Létrehozás</Link>
                    </div>
                    

                </div>
            </div>
        </div>
    )
}



export default Documents
