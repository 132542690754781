import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { GetLanguage } from '../../Services/Includes/Language';
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import './SmartCommunities.css';


const SmartCommunities2 = () => {
    const [content, setContent] = useState([]);
    useEffect(() => {
        axios.get(`content/static/smartcom2/${GetLanguage()}.json`).then((e) => {
            setContent(e.data);
            console.log(e)
        });
    }, []);
    return (
        <>
        <Helmet>
            <title>Smart Communities 2.0 - Smart Communities Platform</title>
        </Helmet>
            <Navbar />
            <div id="adatvedelmi">
                <div className="site__head">
                    <div className="container">
                        <h1 className="site__title">Smart Communities 2.0</h1>
                    </div>
                    
                </div>
                <div className="container">
                    <div className="site__container">
                        <div className="site__content">
                            <div className="smart-projekt">
                            <p className="title">{content.title}</p>
                                <p className='cim' dangerouslySetInnerHTML={{__html: content.cim}}></p>
                                <p className='jegyzek'>
                                    {content.jegyzek}
                                </p>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: content.content}}></div>
                            
                        </div>
                        <div className="site__widgets">

                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    )
}

export default SmartCommunities2
