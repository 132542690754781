import axios from "axios"
import { API_URL } from "../Includes/backend.config";


export const AddCourse = async (author, title, url, intro, category, lang, files, desc) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=course/create`, JSON.stringify({uid: author, title, url, intro, category, lang, files, desc}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    console.log(res.data)
    return res.data?.response;
};

export const GetCourses = async () => {

    const res = await axios.get(`${API_URL}/Routes.php?target=course/get`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,
    });

    return res.data?.response;
}
export const GetCourse = async (id) => {

    const res = await axios.get(`${API_URL}/Routes.php?target=course/get&id=${id}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,
    });

    return res.data?.response;
}

export const DeleteCourse = async (id) => {
    const res = await axios.get(`${API_URL}/Routes.php?target=course/del&id=${id}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });

    return res.data?.response;
}

export const ModifyCourse = async (title, url, intro, category, lang, id, files, desc) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=course/edit`, JSON.stringify({title, url, intro, category, lang, id, files, desc}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });

    console.log(res.data);

    return res.data?.response;
}

export const UploadFile = async (file, folder) => {
    const res = await axios.post(`${API_URL}/functions/videofile.php?folder=${folder}`, file, 
    {
        headers: {
            'Content-Type' : 'multipart/form-data'
        },
        withCredentials: false,

    });

    console.log(res.data);

    return res.data?.response;
}
export const DeleteFile = async (file, id = 0) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=file/delete&course_id=${id}`, JSON.stringify({file}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });

    console.log(res.data);

    return res.data?.response;
}