import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Request from '../../../Services/Documents/DocHandler';
import { GetLanguage } from '../../../Services/Includes/Language';
import Footer from '../../Footer/Footer';
import Navbar from '../../Navbar/Navbar';
import './DocRenderer.css';

export const GetDocCategoryFromUrl = () => {
    const url = window.location.href;

    if(url.includes("docs")) {
        return 1;
    }
    if(url.includes("match-making"))
        return 2;

    if(url.includes("presentations"))
        return 3;
};

const DocRenderer = ({type}) => {

    const {docId} = useParams();
    const [data, setData] = useState([]);
    const [current, setCurrent] = useState(0);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        console.log("DEBUG: Request ", `docs/get&url=${docId}&cat=${GetDocCategoryFromUrl()}&lang=${GetLanguage()}`)
        
        Request("GET", `docs/get&url=${docId}&cat=${GetDocCategoryFromUrl()}&lang=${GetLanguage()}`).then((r) => {
            if(r?.length === 0)
                window.location.href = "/";
            setData(r[0]);
            //console.log("DEBUG: Response: ", r);
            Request("GET", "docs/get_files&id="+r[0].id).then((f) => {
                
                setFiles(f);
                setCurrent(0)
            })
        });

        return () => {
            setData([]);
            setFiles([]);
            setCurrent(0)
        }

    }, [docId]);

  return <>

    <Helmet>
        <title>{`${data?.title}`} - Smart Communities Platform</title>
        <meta name="description" content={`${data?.labels}`} />
    </Helmet>
    <Navbar />

    <div id="doc">
        <div className="site__head">
            <div className="container">
                <h1 className="site__title">{data?.title}</h1>
            </div>
                    
        </div>
        <div className="container">
            <div className="site__container">
                <div className="site__content">
                    <div className='paper__intro' dangerouslySetInnerHTML={{ __html: data?.intro}}></div>

                    {files?.length > 0 && <div>
                        <div className="doc__filter">
                            <div className="filter__wrapper">
                            <label htmlFor="szektorok">{data?.filter}</label>

                                <select id="szektorok" className="select" value={current} onChange={(e) => setCurrent(e.target.value)}>
                                    {files.map((item, i) => (
                                        <option value={i} key={`pdf-${i}`}>{item?.filename}</option>
                                    ))}
                                    
                                </select>
                            </div>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                            <div style={{ height: '800px', width: '100%' }}>
                                {files[current] &&<Viewer fileUrl={ files[current].url} defaultScale={'1.05'} />}
                            </div>
                        </Worker>
                    </div>}

                </div>
            </div>
        </div>
    </div>

    <Footer />
  </>;
};


export default DocRenderer;
