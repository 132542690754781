import React, { useEffect, useRef, useState } from 'react';
import Request, { UploadDocsFile } from '../../Services/Documents/DocHandler';
import './Documents.css';
import { IoFolderOpen, IoCloudUploadSharp } from 'react-icons/io5';

const EditPDF = (props) => {

    const [name, setName] = useState('');
    const Submit = (e) => {
        e.preventDefault();


        Request('POST', `docs/edit_file&id=${props.file.id}`, {name}).then((r) => {
            console.log(r)
            props.Update();
            props.onClose();
        });

        
    }

    useEffect(() => {
        setName(props.file?.filename);
    }, [props]);

    
    
  return <div id={`language__modal`} className={`${props.isShown && "show__lang"}`} onClick={props.onClose}>
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          <div className="modal__head">
              <h3>Fájl szerkesztése</h3>
              <div className="close" onClick={props.onClose}>&times;</div>
          </div>
          
          <div className="modal__body">

              <form onSubmit={Submit}>
                  <div className="input-group mb-4">
                      <input type="text" className="form-control form-sm " placeholder='Név' required value={name} onChange={(e) => setName(e.target.value)} />
                       <small>Milyen néven jelenjen meg a fájl?</small>
                    </div>
                    <button className="btn bg-warning" type='submit'><IoCloudUploadSharp className='btn-icon' /> Mentés</button>
              </form>
               
               
          </div>
      </div>
  </div>;
};

export default EditPDF;
