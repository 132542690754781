export default function GetCategories (lang) {
    switch(lang) {
        case 'hu': return ["Programozás", "Üzleti intelligencia", "Egyéb"]
        case 'en': return ["Programming", "Business intelligence", "Other"]
        default: return ["Programovanie", "Business intelligence", "Iné"]
    }
}

export function YoutubeParser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length===11)? match[7] : false;
}