import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import GetTranslation from '../../Language';
import DateFormatter from '../../Services/Includes/dateformatter';
import { GetSingleNews } from '../../Services/News/News';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

import './SinglePost.css';

const SinglePost = () => {

    const {postId} = useParams();

    const [hir, setHir] = useState([]);

    useEffect(() => {
        console.log("poszt: ", postId)
        GetSingleNews(postId).then((data) => {
            
            setHir(data[0]);
        });
        return () => {
            setHir([]);
        }
    }, [postId]);

    return (
        <>
        <Helmet>
            <title>{`${hir?.title} - Smart Communities Platform`}</title>
            <meta name={"description"} content={hir?.keywords} />
        </Helmet>
        <Navbar />
        <div id="singlepost">
            <div className="container">
            <div className="post__head">
                <div className="published">{GetTranslation("kozzeteve")} {DateFormatter(hir.date)}</div>
                {/*hir?.edited_date && <div className="published" style={{fontSize: '.8em', margin: '1em', color: '#122'}}>(Módosítva: {DateFormatter(hir?.edited_date)})</div> */}
                
                <div className="title">
                    {hir?.title}
                </div>
                {/*<div className="labels">
                    <div className="label">Információ</div>
    </div>*/}
                <div className="share">
                    <a rel="noreferrer" href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(window.location.href)}`} target={"_blank"}>{GetTranslation("share")}</a>
                </div>

            </div>
            <div className="post__body">
                <div className="post__content">
                    <div dangerouslySetInnerHTML={{__html: hir?.content}}></div>
                </div>
                {/*<div className="post__widgets">
                    widget
    </div> */}
            </div>
            </div>

        </div>
        <Footer />
        </>
    )
}

export default SinglePost
