import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Doc from './Components/Docs/Doc';
import Docs from './Components/Docs/Docs';
import NotFound from './Components/NotFound/NotFound';
import Paper from './Components/Paper/Paper';
import Post from './Components/Post/Post';
import SinglePost from './Components/Post/SinglePost';
import ChoosePres from './Components/Presentations/ChoosePres';
import Presentation from './Components/Presentations/Presentation';
import SmartDb from './Components/SmartDb/SmartDb';
import SingleSR from './Components/SmartRanking/SingleSR';
import Smartrank from './Components/SmartRanking/Smartrank';
import Adatvedelmi from './Components/Static/Adatvedelmi';
import Impresszum from './Components/Static/Impresszum';
import SmartCommunities1 from './Components/Static/SmartCommunities1';
import SmartCommunities2 from './Components/Static/SmartCommunities2';
import DocRenderer from './Components/v2/DocRenderer/DocRenderer';
import Video from './Components/Video/Video';
import Videos from './Components/Video/Videos';
import Courses from './Dashboard/Courses/Courses';
import CreateCourse from './Dashboard/Courses/CreateCourse';
import EditCourse from './Dashboard/Courses/EditCourse';
import CreateDocument from './Dashboard/Docs/CreateDocument';
import Documents from './Dashboard/Docs/Documents';
import EditDocument from './Dashboard/Docs/EditDocument';
//import Home from './Dashboard/Home/Home';
import Login from './Dashboard/Login/Login';
import Reg from './Dashboard/Login/Reg';
import AddNews from './Dashboard/News/AddNews';
import EditNews from './Dashboard/News/EditNews';
import News from './Dashboard/News/News';
import Settings from './Dashboard/Settings/Settings';
import Users from './Dashboard/Users/Users';
import './Grid.css';
import ScrollToTop from './ScrollToTop';
import { isLoggedIn } from './Services/Authentication/Auth.inc';


const App = () => {

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Post />} />
        <Route exact path="news" element={<Post />} />
        <Route path="news/:postId" element={<SinglePost />} />
        <Route exact path="docs" element={<Docs />} />
        <Route path="docs/:docId" element={<DocRenderer type={1} />} />
        <Route exact path="videos" element={<Videos />} />
        <Route exact path="match-making" element={<SmartDb />} />

        <Route path="match-making/:docId" element={<DocRenderer type={2} />} />

        <Route exact path="presentations" element={<ChoosePres />} />
        <Route path="presentations/:docId" element={<DocRenderer type={3} />} />
        
        <Route exact path="smart-ranking" element={<Smartrank />} />
        <Route path="smart-ranking/:id" element={<SingleSR />} />



        
        <Route exact path="videos/:VideoID" element={<Video />} />
        <Route exact path="adatvedelmi-nyilatkozat" element={<Adatvedelmi />} />
        <Route exact path="privacy-statement" element={<Adatvedelmi />} />
        <Route exact path="impresszum" element={<Impresszum />} />



       

        <Route exact path="smart-communities-1-0" element={<SmartCommunities1 />} />
        <Route exact path="smart-communities-2-0" element={<SmartCommunities2/>} />

        <Route path='*' element={<NotFound />} />
        <Route path="reg/:regid" element={<Reg />} />

        {/* *** Dashboard *** */}
        {
          isLoggedIn() ?
          <>
            <Route exact path="belepes" element={<News />} />
            <Route exact path="dash" element={<News />} />
            <Route exact path="dash/news" element={<News />} />
            <Route exact path="dash/news/create" element={<AddNews />} />
            <Route path="dash/news/edit/:id" element={<EditNews />} />
            <Route exact path="dash/users" element={<Users />} />
            <Route exact path="dash/courses" element={<Courses />} />
            <Route exact path="dash/courses/create" element={<CreateCourse />} />
            <Route exact path="dash/courses/edit/:id" element={<EditCourse />} />
            <Route exact path="dash/docs" element={<Documents />} />
            <Route exact path="dash/docs/create" element={<CreateDocument />} />
            <Route exact path="dash/docs/edit/:id" element={<EditDocument />} />
            <Route exact path="dash/settings" element={<Settings />} />
          </>
          : 
          <>
            <Route exact path="belepes" element={<Login />} />
            <Route exact path="dash" element={<Login />} />
            <Route exact path="dash/news" element={<Login />} />
            <Route exact path="dash/news/create" element={<Login />} />
            <Route path="dash/news/edit/:id" element={<Login />} />
            <Route exact path="dash/users" element={<Login />} />
          </>
        }
        
          
      </Routes>
    </Router>
  );

  
}

export default App;
