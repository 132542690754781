import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet'
import { IoTimeOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { GetNews, GetNewsByLang } from '../../Services/News/News';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar'
import './Post.css';

import DefaultNews from '../../Assets/projektlogo.jpg'
import DateFormatter from '../../Services/Includes/dateformatter';
import GetTranslation from '../../Language';

const hirek = [
    {id: 1, title: 'Teszt', image: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80', content:"Voluptate incidunt, eum est odit? Autem iure sint iusto consequatur et cum, veritatis modi."},
    {id: 2, title: 'Teszt', image: 'https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1025&q=80', content: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi libero similique sed minima consectetur repellat culpa."},
    {id: 3, title: 'Teszt', image: 'https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80', content:"Voluptate incidunt, eum est odit? Autem iure sint iusto consequatur et cum, veritatis modi."},
    {id: 4, title: 'Teszt', image: 'https://images.unsplash.com/photo-1593642634443-44adaa06623a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1025&q=80', content: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Commodi libero similique sed minima consectetur repellat culpa."},
]; 



const Post = () => {

    const [Hirek, setHirek] = useState([]);
    useEffect(() => {
        GetNewsByLang().then((data) => {
            setHirek(data);
        })
        return () => {
            setHirek([]);
        }
    }, [])
    return (
        <>
         <Helmet>
            <title>{GetTranslation("hirek")} - Smart Communities Platform</title>
        </Helmet>
        <Navbar />
        <div id="post">
            <div className="container">
                <h2>{GetTranslation("hirek")}</h2>
                {Hirek.length === 0 && <h3 style={{textAlign: 'center', marginTop: '3em', alignSelf: 'center'}}>Nincs rendelkezésre álló bejegyzés.</h3>}
                <div className="posts-container">
                    
                    {Hirek.map((item, i) => (
                        <Link to={`/news/${item.url}`} key={`post-item-${i}`} style={{textDecoration: 'none'}}>
                            <SinglePost data={item}  />
                        </Link>
                    ))}
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

const SinglePost = ({data}) => {

    return (
        <div className='post'>
            <div className="image"><img src={DefaultNews} alt={data.title} /></div>
            <div className="content">
                <div className="title">{data.title}</div>
               
               
            </div>
            <div className="info">
                <div className="time">
                    <IoTimeOutline className='post-icon' /> <span>{DateFormatter(data.date)}</span>
                </div>
            </div>
            
            
        </div>
    );

};

export default Post
