import React, {useRef, useState} from 'react'
import '../Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';

import { Editor } from '@tinymce/tinymce-react';
import { IoCheckmarkCircle } from 'react-icons/io5';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { API_URL } from '../../Services/Includes/backend.config';

import './Documents.css';
import Request from '../../Services/Documents/DocHandler';
import { useNavigate } from "react-router-dom";

const CreateDocument = () => {

    const editorRef = useRef(null);
    const [title, setTitle] = useState('');
    const [labels, setLabels] = useState('');
    const [url, setUrl] = useState('');
    const [lang, setLang] = useState('hu');
    const [cat, setCat] = useState('1');
    const [filter, setFilter] = useState('');
    let navigate = useNavigate();

    const Submit = (e) => {
        e.preventDefault();
        if(!title) {
            toast.error('A cím nem maradhat üres!')
            return;
        }

        toast.promise(
            Request("POST", "docs/create", { title, author_id: JSON.parse(localStorage.getItem("ita-login")).uid, intro: editorRef.current?.getContent(), url, lang, labels, cat, filter }),
            {
                loading: () => `Létrehozás folyamatban...`,
                success: (data) =>  {
                    navigate(`/dash/docs/edit/${data}`);
                    return `Dokumentum létrehozva (#${data}).`
                },
                error: (err) => `Nem sikerült a dokumentum létrehozása: ${err.toString()}`,
            },
            {
                duration: 4000
            }
        );
      

    };

    const changeLetter = (letter) => {

        switch (letter.toLowerCase()) {
            case 'á': return 'a'
            case 'í': return 'i'
            case 'ó':
            case 'ö':
            case 'ő': return 'o';
            case 'ú':
            case 'ü':
            case 'ű': return 'u';
            case 'é': return 'e';
        
            default: return '-';
        }
    };
    
    const Go = (str) => {
        let allowedChars = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z", "1", "2", "3", "4", "5", "6", "7", "8", "9", '0', "-"];
        var result = "";
        var temp = "";
        for (let i = 0; i < str.length; i++) {
            temp = str[i];
            if(!allowedChars.includes(str[i].toUpperCase())) {      
                temp = changeLetter(temp);
            }
            result = result.concat(temp.toLocaleLowerCase());
        }
        return result;
    
    };

    React.useEffect(() => {
        setUrl(Go(title));
    }, [title]);


    


    return (
        <div className='dashboard__page'>
            
            <Sidebar />
            <div className="dashboard__content" id="home">
            <Toaster />
                <h1>Új dokumentum létrehozása</h1>
                <div className="create__news">
                    <div>
                    <div className="card">
                        <div className="card__body">
                            <form id="create-news-form">
                                <div className="input-group">
                                    <input type="text" className='form-control' placeholder='Cím' value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </div>
                            </form>
                            <div className='mb-4'>
                            <Editor
                            
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue=""
                                apiKey='em5jpi5sf02sb0fa7qcqtpi4meikooepovwv0jdcpyd94lon'
                                
                                init={{placeholder: 'Bevezető leírás',  language:"hu_HU", height: 500, entity_encoding: 'raw', 
                                    plugins: 'fullscreen wordcount autosave autoresize hr table emoticons preview image imagetools',
                                    toolbar:    'insertfile undo redo | formatselect | ' +
                                                'bold italic backcolor hr | alignleft aligncenter ' +
                                                'alignright alignjustify | table | bullist numlist outdent indent | ' +
                                                'removeformat | help | preview image',
                                    file_picker_types: "file image media",
                                    
                                    images_upload_handler: async function(
                                        blobInfo,
                                        success,
                                        failure
                                        ) {
                                            let imageFile = new FormData();
                                            imageFile.append("files[]", blobInfo.blob());

                                            axios.post(API_URL + `/functions/fileupload.php`, imageFile, {headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }}).then((data) => success(data.data.response));
                                        }

                                }}
                            />
                            </div>
                            
                            <div className="input-group">
                                <input type="text" className='form-control form-sm' placeholder='Fájlszűrő címke (pl.: Keresés szektor alapján)' value={filter} onChange={(e) => setFilter(e.target.value)} />
                            </div>
                            <h5>A fájlok feltöltése a dokumentum létrehozása után lehetséges!</h5>


                        </div>
                    </div>
                    
                    <div className="card">
                            <div className="card__head"><h3>Beállítások</h3></div>
                            <div className="card__body">
                                <div>
                                    <small>Melyik oldalon jelenjen meg a dokumentum?</small>
                                </div>
                                <select id="nyelv" className="select mb-4 form-sm" value={cat} onChange={(e) => setCat(e.target.value)}>
                                    <option value="1">Dokumentumok</option>
                                    <option value="2">Match-making adatbázis</option>
                                    <option value="3">Prezentáció</option>
                                </select>
                                
                                Nyelv
                                <select id="nyelv" className="select mb-4 form-sm" value={lang} onChange={(e) => setLang(e.target.value)}>
                                    <option value="hu">Magyar</option>
                                    <option value="en">Angol</option>
                                    <option value="sk">Szlovák</option>
                                </select>
                                Leírás 
                                <textarea className="form-control form-sm" placeholder='Valamilyen leírás' value={labels} onChange={(e) => setLabels(e.target.value)} />
                                <small>Ez a leírás megosztások esetén jelenik meg (pl.: Facebookon), üresen hagyható.</small>
                                <div className="mb-2"></div>

                                <div className="mb-2"></div>
                                <input type="text" className="form-control form-sm" placeholder='URL' value={url} onChange={(e) => setUrl(e.target.value)} />
                                <small>Automatikusan generált, megváltoztatható hivatkozásazonosító. [<strong>Később nem szerkeszthető!</strong>]</small>
                            </div>
                        </div>
                    </div>
                    <div className="news-settings">
                       
                        <div className="card">
                            <div className="card__head"><h3>Közzététel</h3></div>
                            <div className="card__body">
                                <button className="btn bg-primary" onClick={Submit}><IoCheckmarkCircle className='btn-icon' /> Létrehozás</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    )
}

export default CreateDocument
