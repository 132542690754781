import React, {useEffect, useState} from 'react'
import DataTable from 'react-data-table-component';
import toast, {Toaster} from 'react-hot-toast';
import { IoAddCircleSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { DeleteCourse, GetCourses } from '../../Services/Courses/Course';
import GetCategories from '../../Services/Includes/categories';
import '../Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';


const columns = [
    {
        name: '#',
        selector: row => Number(row.id),
        sortable: true,
        maxWidth: "20px"
    },
    {
        name: 'Cím',
        selector: row => row.title,
        sortable: true
    },
    {
        name: 'Instruktor',
        selector: row => row.author,
        sortable: true,
        cell: row => <div className='author'>{row.author}</div>
    },
    {
        name: 'Kategória',
        selector: row => row.category,
        cell: row => GetCategories("hu")[row.category-1],
        sortable: true
    },
];


const Courses = () => {

    const [courses, setCourses] = useState([]);
    useEffect(() => {
        toast.promise(GetCourses().then((data) => setCourses(data)), {
            loading: 'Kurzusok betöltése...',
            success: (data) =>  `Kurzusok betöltve.`,
            error: 'Nem sikerülüt betölteni a kurzusokat.',
        })
        //GetNews();
        return () => {
            setCourses([]);
        }
    }, [])

    return (
        <div className='dashboard__page'>
            <Sidebar />
            <div className="dashboard__content" id="home">
                <Toaster />
                <h1>Kurzusok</h1>

                <div className="card">
                    <div className="card__head">
                        <h3>Kurzusok</h3>
                    </div>
                    <div className="card__body">
                        <DataTable columns={columns} data={courses} highlightOnHover pagination expandableRows expandOnRowClicked expandableRowsComponent={DetailedCourse} />
                        <Link to="create" className='btn bg-primary mb-2'><IoAddCircleSharp className='btn-icon' /> Létrehozás</Link>
                    </div>
                </div>
            </div>
        </div>
    )
};

const DetailedCourse = ({data}) => {
    const Remove = () => {
        if(window.confirm(`Biztos, hogy törli a kiválasztott kurzust? (#${data.id})`)) {
            
            toast.promise(DeleteCourse(data.id), {
                loading: 'Kurzus törlése..',
                success: (data) =>  `Kurzus eltávolítva.`,
                error: 'Nem sikerült eltávolítani a kijelölt kurzust.',
            })
        }
    };
    return (
        <div className="news__detail">
            <Link to={`/videos/${data.id}`} target={"_blank"} className="news-btn bg-primary">Előnézet</Link>
            <Link to={`edit/${data.id}`} className="news-btn bg-warning">Szerkesztés</Link>
            
            <button onClick={Remove} className="btn bg-danger news-btn">Törlés</button>
        </div>
    );
};


export default Courses
