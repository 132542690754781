import React from 'react';
import './Footer.css';
import ItaLogo from '../../Assets/image/partner/ITA.png';
import ElteLogo from '../../Assets/image/partner/elte_cimer_szines.jpg';
import SkLogo from '../../Assets/image/partner/EKF1_SK_C_CMYK.jpg';
import Logo from '../../Assets/logo_hu.png';
import PartnersegetEpitunk from '../../Assets/image/hu/slogen_hu.png';
import { Link } from 'react-router-dom';
import GetTranslation from '../../Language';




const Footer = () => {
    return (
        <>
        <div id="footer">
            <div className="container" >
                <div className="content">
                    <div className="section">
                        <img src={GetTranslation("logo")} alt="Logo" />
                        <p className='desc'>{GetTranslation("tamogato")}</p>

                        <img src={GetTranslation("slogen")} alt="Partnerséget építünk" style={{marginTop: '1em'}} />
                        <h4>{GetTranslation("interreg")}</h4>
                        <a href="http://www.skhu.eu" rel="noreferrer" target={"_blank"} className='partner__web'>www.skhu.eu</a>
                        
                        
                    </div>
                    <div className="section">
                        <h3>{GetTranslation("projekt-partnerek")}</h3>
                        <Partner  data={{nev: GetTranslation("interindustria"), cim: '3100 Salgótarján, Úttörők útja 6.', web: 'www.interindustria.hu', img: ItaLogo}} />
                        
                        <Partner  data={{nev: GetTranslation("kassaiegyetem"), cim: 'Letná 9, 040 01 Košice, Slovak Republic', web: 'www.tuke.sk', img: SkLogo}} />
                        <Partner  data={{nev: GetTranslation("elte"),
                        cim: '1117 Budapest, Pázmány Péter sétány 1/C', web: 'rtt.elte.hu', img: ElteLogo}} />
                    </div>

                    <div className="section">
                        <h3>{GetTranslation("informacio")}</h3>
                        <p><Link to="/impresszum">{GetTranslation("impresszum")}</Link></p>
                        <p style={{marginBottom: '1.5em'}}><Link to="/adatvedelmi-nyilatkozat">{GetTranslation("adatvedelmi")}</Link></p>
                        <h3>{GetTranslation("kapcsolat")}</h3>
                        <p>Smart Communities 2.0 – {GetTranslation("howtobesmart")}</p>
                        
                        <p><a href="http://smartcommunities.eu" rel="noreferrer" target={"_blank"} className='partner__web'>www.smartcommunities.eu</a></p>
                        <p>E-mail: <a href="mailto:smartcommunities2.0@gmail.com" rel="noreferrer" target={"_blank"} className='partner__web'>smartcommunities2.0@gmail.com</a></p>
                        
                    </div>
                </div>
                
            </div>
            
        </div>
        <div id="bottom-footer">
            <div className="container">
                <div className="bottom">
                    <p>&copy; {new Date().getFullYear()} Smart Communities | {GetTranslation("mindenjogfenntartva")}</p>
                    {/*<img src={footerLogo} alt="Smart Communities Logo" />*/}
                </div>
            </div>
        </div>
        </>
    )
}

const Partner = ({data}) => {
    return (
        <div className="partner">
            <div className="left">
                <div className="partner__logo"><img src={data.img} alt={data.nev} /></div>
            </div>
            <div className="right">
                <div className="partner__nev">{data.nev}</div>
                <div className="partner__cim">{data.cim}</div>
                <a className="partner__web" href={'http://' + data.web} target={"_blank"} rel='noreferrer'>{data.web}</a>
            </div>
            
            
        </div>
    );
};

export default Footer
