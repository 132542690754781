import React from 'react'
import './Widget.css';
const Widget = ({title, children, style}) => {
    return (
        <>
            <div className="widget" style={style}>
                <h3>{title}</h3>
                <div className="widget__content">
                    {children}
                </div>
            </div>
        </>
    )
}

export default Widget
