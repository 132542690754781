
import { GetLanguage } from "./Services/Includes/Language";

import logohu from './Assets/logo_hu.png';
import logoen from './Assets/image/en/logo_en.png';
import logosk from './Assets/image/sk/logo_sk.png';

import slogenhu from './Assets/image/hu/slogen_hu.png';
import slogenen from './Assets/image/en/slogen_en.png';
import slogensk from './Assets/image/sk/slogen_sk.png';


export default function GetTranslation(key) {

    const hu = {
        /* Nav */
        "hirek": "Hírek",
        "dokumentumok": "Dokumentumok",
        "videok": "Videók",
        "projektek": "Projektek",
        "mm-adatbazis": "Match-making adatbázis",
        "login": "Belépés",
        "prezentaciok": "Prezentációk",
        "smartranking": "Smart ranking of municipalities",
        /* News */
        "kozzeteve": "Közzétéve",
        "share": "Megosztás",

        /* Video */
        "kategoria": "Kategória",
        "megtekintes": "Megtekintés",
        "leiras": "Leírás",
        "csatolmany": "Csatolmányok",
        "letoltes": "Letöltés",


        /* Footer */
        "tamogato": "A projekt az Európai Unió támogatásával, az Európai Regionális Fejlesztési Alap társfinanszírozásával valósul meg.",
        "impresszum": "Impresszum",
        "adatvedelmi": "Adatvédelmi nyilatkozat",
        "projekt-partnerek": "Projekt partnerek",
        "informacio": 'Információ',
        "kapcsolat": "Elérhetőség",
        "mindenjogfenntartva": "Minden jog fenntartva.",

        "howtobesmart": "Hogyan lehetünk okosak vidéken?",
        "logo": logohu,
        "slogen": slogenhu,
        "interreg": "Interreg V-A Szlovákia-Magyarország Együttműködési Program",

        "interindustria": "Interindustria Tudásközpont Alapítvány",
        "elte": "Eötvös Loránd Tudományegyetem (Földrajz- és Földtudományi Intézet, Földrajztudományi Központ, Regionális Tudományi Tanszék)",
        "kassaiegyetem": "Kassai Műszaki Egyetem",


        /* Doc */
        "bevezeto": "Bevezető",
        "kereses-szektor": "Keresés szektor alapján:"
    }
    const en = {
        /* Nav */
        "hirek": "News",
        "dokumentumok": "Documents",
        "videok": "Videos",
        "projektek": "Projects",
        "mm-adatbazis": "Match-making database",
        "login": "Login",
        "prezentaciok": "Presentations",
        "smartranking": "Smart ranking of municipalities",

        /* News */
        "kozzeteve": "Published on",
        "share": "Share",

        /* Video */
        "kategoria": "Category",
        "megtekintes": "Watch",
        "leiras": "Description",
        "csatolmany": "Attachments",
        "letoltes": "Download",

        /* Footer */
        "tamogato": "The project is supported by European Union, co-financed by the European Regional Development Fund.",
        "impresszum": "Impressum",
        "adatvedelmi": "Privacy Statement",
        "projekt-partnerek": "Project partners",
        "informacio": 'Information',
        "kapcsolat": "Contact",
        "mindenjogfenntartva": "All rights reserved.",

        "howtobesmart": "How to be smart in the countryside?",
        "logo": logoen,
        "slogen": slogenen,
        "interreg": "Interreg V-A Slovakia-Hungary Cooperation Programme",


        "interindustria": "Interindustria Knowledge Center Foundation",
        "elte": "Eötvös Loránd University",
        "kassaiegyetem": "Technical University of Košice",
        

        /* Doc */
        "bevezeto": "Introduction",
        "kereses-szektor": "Search by sector:",




    }

    const sk = {
        /* Nav */
        "hirek": "Správy",
        "dokumentumok": "Dokumenty",
        "videok": "Videá",
        "projektek": "Projekty",
        "mm-adatbazis": "Match-making databáza",
        "login": "Prístup",
        "prezentaciok": "Prezentácie",
        "smartranking": "Inteligentné hodnotenie obcí",


        /* News */
        "kozzeteve": "Uverejnený",
        "share": "Zdieľam",

        /* Video */
        "kategoria": "Kategórii",
        "megtekintes": "Vyhliadka",
        "leiras": "Popis",
        "csatolmany": "Príloh",
        "letoltes": "Stiahnuť",


        /* Footer */
        "tamogato": "Projekt bol spolufinancovaný z prostriedkov Európskej únie z Európskeho fondu regionálneho rozvoja.",
        "impresszum": "Impressum",
        "adatvedelmi": "Vyhlásenie o ochrane osobných údajov",
        "projekt-partnerek": "Projektových partnerov",
        "informacio": 'Informácie',
        "kapcsolat": "Kontakt",
        "mindenjogfenntartva": "Všetky práva vyhradené.",

        "howtobesmart": "Ako byť inteligentný na vidieku?",
        "logo": logosk,
        "slogen": slogensk,
        "interreg": "Programu spolupráce Interreg V-A Slovenská republika-Maďarsko",


        "interindustria": "Nadácia vedomostného centra Interindustria",
        "kassaiegyetem": "Technická univerzita v Košiciach",
        "elte": "Univerzita Eötvösa Loránda",


        /* Doc */
        "bevezeto": "Úvodná stránka",
        "kereses-szektor": "Vyhľadávanie podľa sektora:",

    }

    const lang = GetLanguage();
    switch (lang) {
        case 'hu':
            return hu[key];
        case 'en': 
            return en[key];
        case 'sk': 
            return sk[key];
        default:
            return hu[key];
    }
}



