import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GetTranslation from '../../Language';
import { GetLanguage } from '../../Services/Includes/Language';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

import KezikonyvIMG from '../../Assets/image/docs/kezikonyv.png';
import OkosIMG from '../../Assets/image/docs/okosfalu.png';

const Smartrank = () => {

    const GetDocsByLang = () => {
        switch(GetLanguage()) {
            case 'hu': return <SmartHU />
            case 'en': return <SmartEN/>
            case 'sk': return <SmartSK/>
            default: return <SmartHU />
        }
    }
  return <>
    <Helmet>
        <title>{`${GetTranslation("smartranking")}`}- Smart Communities Platform</title>
    </Helmet>
    <Navbar />
    <div id="docs">
        <div className="container">
            <div className="docs__head">
                <h1 className="title">{GetTranslation("smartranking")}</h1>
            </div>
            <div className="docs__wrapper">
                <div className="docs__content">
                {GetDocsByLang()}
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>;
};

const SmartHU = () => {
    return (
        <div>
            <div className="docs__container">

                <div className="doc__content">
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'main-results', title: 'Main Results'}} />
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'ranking', title: 'Ranking'}} />
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'detailed-results', title: 'Detailed Results'}} />

                </div>           
            </div>      
        </div>
    );
};
const SmartEN = () => {
    return (
        <div>
            <div className="docs__container">

                <div className="doc__content">
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'main-results', title: 'Main Results'}} />
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'ranking', title: 'Ranking'}} />
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'detailed-results', title: 'Detailed Results'}} />

                </div>           
            </div>      
        </div>
    );
};

const SmartSK = () => {
    return (
        <div>
            <div className="docs__container">

                <div className="doc__content">
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'hlavne-vysledky', title: 'Hlavné výsledky'}} />
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'rebricek', title: 'Rebríček'}} />
                    <SingleDoc data={{icon: KezikonyvIMG, url: 'podrobne-vysledky', title: 'Podrobné výsledky'}} />

                </div>           
            </div>      
        </div>
    );
};

const SingleDoc = ({data}) => {
    return (
        <Link to={`/smart-ranking/${data.url}`} >
            <div className='doc'>
                <img src={data.icon} alt={data.title} className="doc__icon" />
                <p className="doc__name">{data.title}</p>
            </div>
        </Link>
    );
};

export default Smartrank;
