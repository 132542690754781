import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import './NotFound.css';


const NotFound = () => {

    return (
        <>
        <Navbar />
         <div id="notfound">
            <div className="url"><a href={window.location.href}>{window.location.href}</a></div>
            <div className="title">404 Tartalom nem található.</div>
            <p className='msg'>Lehetséges, hogy elgépelte a keresett tartalomra vezető elérési utat.</p>
         </div>  
         <Footer />
        </>
    )
}

export default NotFound
