import React, {useEffect, useRef, useState} from 'react'
import '../Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';

import { Editor } from '@tinymce/tinymce-react';
import { IoCheckmarkCircle, IoImageSharp, IoEye, IoSettingsSharp } from 'react-icons/io5';
import { MdPictureAsPdf, MdAttachFile, MdOutlineDeleteOutline } from 'react-icons/md';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { API_URL } from '../../Services/Includes/backend.config';

import './Documents.css';
import AddPDF from './AddPDF';
import { useParams } from 'react-router-dom';
import Request from '../../Services/Documents/DocHandler';
import EditPDF from './EditPDF';

const EditDocument = () => {

    const {id} = useParams();

    const [docId, setDocId] = useState(0);

    const iconUploader = useRef();
    const editorRef = useRef(null);
    const [initialEditor, setInitialEditor] = useState(''); 

    const [title, setTitle] = useState('');
    const [labels, setLabels] = useState('');
    const [url, setUrl] = useState('');
    const [lang, setLang] = useState('hu');
    const [cat, setCat] = useState('1');
    const [filter, setFilter] = useState('');


    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const [files, setFiles] = useState([]);

    const [icon_url, setIconUrl] = useState('');

    const Update = () => {
        setFiles([]);
        Request('GET', `docs/get_files&id=${docId}`).then((r) => {
            setFiles(r);
        });
    };

    const SetIcon = (e) => {
        e.preventDefault();
       
        setIconUrl(URL.createObjectURL(e.target.files[0]));
        UploadIcon(e.target.files[0]);

    };
    const UploadIcon = (icon) => {
        let formData = null;

        formData = new FormData();
        formData.append("file", icon);
        

        axios.post(`${API_URL}/functions/docicon.php?id=${docId}`, formData, 
        {
            headers: {
                'Content-Type' : 'multipart/form-data'
            },
            withCredentials: false,
        }).then((r) => {
            console.log(r);
        });

    };
    const Submit = (e) => {
        e.preventDefault();
        if(!title) {
            toast.error('A cím nem maradhat üres!')
            return;
        }

        toast.promise(
            Request("POST", "docs/edit", { title, intro: editorRef.current?.getContent(), id: docId, lang, labels, cat, filter }),
            {
                loading: () => `Módosítás folyamatban...`,
                success: (data) =>  `Dokumentum módosítva.`,
                error: (err) => `Nem sikerült a dokumentum módosítása: ${err.toString()}`,
            },
            {
                duration: 4000
            }
        );
      

    };

    useEffect(() => {

        // Dokumentum lekérdezése
        Request('GET', 'docs/get&id=' + id).then((r) => {
            console.log(r);
            setTitle(r.title);
            setInitialEditor(r.intro);
            setLang(r.lang);
            setUrl(r.url);
            setLabels(r.labels)
            setCat(r.category)
            setIconUrl(r.icon_url);
            setFilter(r.filter)
            setDocId(r.id);
        });
        


        // Fájlok lekérdezése
        GetFile();
    }, [id]);


    const GetFile = async () => {
        Request('GET', `docs/get_files&id=${id}`).then((r) => {
            setFiles(r);
            console.log("DOCID: ", id)
        });
    };


    const RemoveFile = (id) => {
        if(!window.confirm("Biztos, hogy törli a kijelölt fájlt?"))
            return;
        Request("GET", "docs/remove_file&id="+id).then((r) => {
            if(r === "OK") {
                toast.success("Fájl törölve.");
                Request('GET', `docs/get_files&id=${id}`).then((r) => {
                    setFiles(r);
                });
            }
        });
    };

    const [editId, setEditId] = useState(null);

    const Edit = (id) => {
        setShowEdit(true);
        setEditId(id);
    };


    return (
        <div className='dashboard__page'>
            
            <Sidebar />
            <div className="dashboard__content" id="home">
            <Toaster />
                <h1>Dokumentum módosítása</h1>
                <div className="create__news">
                    <div>
                    <div className="card">
                        <div className="card__body">
                            <form id="create-news-form">
                                <div className="input-group">
                                    <input type="text" className='form-control' placeholder='Cím' value={title} onChange={(e) => setTitle(e.target.value)} />
                                </div>
                            </form>
                            <div className="mb-4">
                            <Editor
                            
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={initialEditor}
                                apiKey='em5jpi5sf02sb0fa7qcqtpi4meikooepovwv0jdcpyd94lon'
                                
                                init={{placeholder: 'Bevezető leírás',  language:"hu_HU", height: 500, entity_encoding: 'raw', 
                                    plugins: 'fullscreen wordcount autosave autoresize hr table emoticons preview image imagetools',
                                    toolbar:    'insertfile undo redo | formatselect | ' +
                                                'bold italic backcolor hr | alignleft aligncenter ' +
                                                'alignright alignjustify | table | bullist numlist outdent indent | ' +
                                                'removeformat | help | preview image',
                                    file_picker_types: "file image media",
                                    
                                    images_upload_handler: async function(
                                        blobInfo,
                                        success,
                                        failure
                                        ) {
                                            let imageFile = new FormData();
                                            imageFile.append("files[]", blobInfo.blob());

                                            axios.post(API_URL + `/functions/fileupload.php`, imageFile, {headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }}).then((data) => success(data.data.response));
                                        }

                                }}

                            />
                            </div>
                            <div className="input-group">
                                <input type="text" className='form-control form-sm' placeholder='Fájlszűrő címke (pl.: Keresés szektor alapján)' value={filter} onChange={(e) => setFilter(e.target.value)} />
                            </div>
                            
                            <div className="doc_icon_upload">
                                <button className="btn bg-primary" onClick={() => iconUploader.current?.click()}><IoImageSharp className='btn-icon' /> Ikon feltöltés</button>
                                <input type="file" style={{display: 'none'}} ref={iconUploader} accept={'.png,.jpg'} onChange={SetIcon} />
                                <small>Ez az ikon fog megjelenni a dokumentum listázásánál.</small>
                                {icon_url && <img src={icon_url} alt="Dok ikon" />}
                            </div>

                            
                        </div>
                    </div>
                    
                    <div className="card">
                        <div className="card__head"><h3>Fájlfeltöltés</h3></div>
                        <div className="card__body">
                            <div className="files">
                                <div className="file upload" onClick={() => setShowAdd(true)}>
                                    <div className="file__upload__pdf file__icon">
                                        <MdAttachFile />
                                    </div> 
                                    <div className="file__name">Fájl feltöltése</div>
                                </div>
                                {/*<div className="file">
                                    <div className="file__icon"><MdPictureAsPdf /></div>
                                    <p className="file__name">GPC_EN_Tourism - Recreation - Culture_pw.pdf</p>
                                    <div className="file__actions">
                                        <button className="btn bg-primary"><IoEye  /></button>
                                        <button className="btn bg-remove"><MdOutlineDeleteOutline  /></button>
                                    </div>
                            </div>  */}  


                                {files.map((item, i) => (
                                    <div className="file" key={`doc-file-${i}`}>
                                        <div className="file__icon"><MdPictureAsPdf /></div>
                                        <p className="file__name" title={item.url}>{item.filename}</p>
                                        <div className="file__actions">
                                            <a href={item.url} target={"_blank"} className="btn bg-primary"><IoEye  /></a>
                                            <button className="btn bg-success" onClick={() => Edit(item)}><IoSettingsSharp  /></button>
                                            <button className="btn bg-remove" onClick={() => RemoveFile(item.id)}><MdOutlineDeleteOutline  /></button>
                                        </div>
                                    </div>  
                                ))}
                                
                                 
                            </div>
                        </div>
                    </div>
                    <div className="card">
                            <div className="card__head"><h3>Beállítások</h3></div>
                            <div className="card__body">
                                <div>
                                    <small>Melyik oldalon jelenjen meg a dokumentum?</small>
                                </div>
                                <select id="nyelv" className="select mb-4 form-sm" value={cat} onChange={(e) => setCat(e.target.value)}>
                                    <option value="1">Dokumentumok</option>
                                    <option value="2">Match-making adatbázis</option>
                                    <option value="3">Prezentáció</option>
                                </select>
                                
                                Nyelv
                                <select id="nyelv" className="select mb-4 form-sm" value={lang} onChange={(e) => setLang(e.target.value)}>
                                    <option value="hu">Magyar</option>
                                    <option value="en">Angol</option>
                                    <option value="sk">Szlovák</option>
                                </select>
                                Leírás 
                                <textarea className="form-control form-sm" placeholder='Valamilyen leírás' value={labels} onChange={(e) => setLabels(e.target.value)} />
                                <small>Ez a leírás megosztások esetén jelenik meg (pl.: Facebookon), üresen hagyható.</small>
                                <div className="mb-2"></div>

                                <div className="mb-2"></div>
                                <input type="text" className="form-control form-sm" readOnly placeholder='URL' value={url} onChange={(e) => setUrl(e.target.value)} />
                                <small>Automatikusan generált, megváltoztatható hivatkozásazonosító. [<strong>Később nem szerkeszthető!</strong>]</small>
                            </div>
                        </div>
                    </div>
                    <div className="news-settings">
                       
                        <div className="card">
                            <div className="card__head"><h3>Közzététel</h3></div>
                            <div className="card__body">
                                <button className="btn bg-success" onClick={Submit}><IoCheckmarkCircle className='btn-icon' /> Mentés</button>
                            </div>
                        </div>
                    </div>

                    <AddPDF isShown={showAdd} onClose={() => setShowAdd(false)} Update={Update} docId={docId} />
                    {files?.length > 0 && <EditPDF isShown={showEdit} onClose={() => setShowEdit(false)} Update={Update} docId={id} file={editId} />}
                    
                </div>
                
            </div>
        </div>
    )
}

export default EditDocument
