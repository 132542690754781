import React, { useEffect, useState } from 'react'
import toast, {Toaster} from 'react-hot-toast';
import { ChangePasswd, GetUser } from '../../Services/Authentication/Auth.inc';
import '../Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';


const Settings = () => {

    const [passwd, setPasswd] = useState('');
    const [passwd2, setPasswd2] = useState('');

    const Submit = () => {
        if(passwd === '') {
            
            toast.error("A jelszó nem lehet üres!");
            return;
        }
        if(passwd !== passwd2) {
            toast.error("A két jelszó nem egyezik meg!");
            return;
        }

        toast.promise(ChangePasswd(GetUser().uid, passwd), {
            loading: 'Jelszó megváltoztatása folyamatban...',
            success: 'A jelszó meg lett változtatva.',
            error: 'Nem sikerült megváltoztatni a jelszót'
        });

    };

    return (
        <div className='dashboard__page'>
            <Sidebar />
            <div className="dashboard__content" id="home">
                <Toaster />
                <h1>Beállítások</h1>
                
                <div className="card">
                    <div className="card__head"><h3>Beállítások</h3></div>

                    <div className="card__body">
                        <input type="password" id="passwd1" value={passwd} onChange={(e) => setPasswd(e.target.value)} className='form-control form-sm mb-2' placeholder='Új jelszó'  />
                        <input type="password" id="passwd2" className='form-control form-sm mb-4' value={passwd2} onChange={(e) => setPasswd2(e.target.value)} placeholder='Új jelszó megerősítése' />
                        <button className="btn bg-success" onClick={() => Submit()}>Jelszó megváltoztatása</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings
