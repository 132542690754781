import React, {useEffect, useState} from 'react'
import DataTable from 'react-data-table-component';
import toast, {Toaster} from 'react-hot-toast';
import { GetUsers, InviteUser } from '../../Services/Authentication/Auth.inc';
import DateFormatter from '../../Services/Includes/dateformatter';
import '../Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';
import AddUser from './AddUser';
import './Users.css';


const columns = [
    {
        name: '#',
        selector: row => row.id,
        sortable: true,
        maxWidth: "20px"
    },
    {
        name: 'E-mail',
        selector: row => row.email,
        sortable: true
    },
    {
        name: 'Név',
        selector: row => row.nev,
        sortable: true,
        cell: row => <div className='author'>{row.nev}</div>
    },
    {
        name: 'Regisztrált',
        selector: row => row.date === null ? "nem" : DateFormatter(row.reg_date),
        sortable: true
    },
];
const Users = () => {

    const [users, setUsers] = useState([]);

    const [invmail, setInvmail] = useState('');
    const [showInvite, setShowInvite] = useState(false);

    const Invite = () => {
        if(invmail) {
            toast.promise(
                InviteUser(invmail),
                {
                    loading: 'Meghívó küldése folyamatban...',
                    success: 'Meghívó elküldve a megadott címre.',
                    error: (error) =>  `Nem sikerült a meghívó elküldése. (${error})`
                }, {
                    duration: 4000
                }
            )
            ;
        }
    };

    useEffect(() => {
        GetUsers().then((data) => {
            setUsers(data);
        })
        return () => {
            setUsers([]);
        }
    }, [])
    return (
        <div className='dashboard__page'>
            <Sidebar />
            <div className="dashboard__content" id="home">
                <Toaster />
                <h1>Felhasználók</h1>
                <div className="card">
                    <div className="card__head">
                        <h3>Felhasználók</h3>
                        <div id="invite">
                            <input type="email" className="form-control" value={invmail} onChange={(e) => setInvmail(e.target.value)} style={{fontSize: '.8em', width: '300px'}} placeholder='Meghívandó személy e-mail címe' />
                            <button className="btn bg-primary" onClick={Invite}>Meghívás</button>
                        </div>

                        <DataTable columns={columns} data={users} highlightOnHover/>

                        <div className="mb-4"></div>
                        <button className="btn bg-success" onClick={() => setShowInvite(true)}>Új felhasználó</button>
                    </div>
                </div>
                <AddUser isShown={showInvite} onClose={() => setShowInvite(false)} />
            </div>
        </div>
    )
}

export default Users
