import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { IoAddCircleSharp } from 'react-icons/io5';
import { DeleteNews, GetNews, GetNewsByLang } from '../../Services/News/News';
import Sidebar from '../Sidebar/Sidebar';
import './News.css';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import DateFormatter from '../../Services/Includes/dateformatter';



const columns = [
    {
        name: '#',
        selector: row => Number(row.id),
        sortable: true,
        maxWidth: "20px"
    },
    {
        name: 'Cím',
        selector: row => row.title,
        sortable: true
    },
    {
        name: 'Szerző',
        selector: row => row.author,
        sortable: true,
        cell: row => <div className='author'>{row.author}</div>
    },
    {
        name: 'Létrehozva',
        selector: row => DateFormatter(row.date),
        sortable: true
    },
];


const News = () => {
    
    const [Hirek, setHirek] = useState([]);

    useEffect(() => {
        toast.promise(GetNews().then((data) => setHirek(data)), {
            loading: 'Hírek betöltése...',
            success: (data) =>  `Hírek betöltve.`,
            error: 'Nem sikerülüt betölteni a híreket.',
        })
        //GetNews();
        return () => {
            setHirek([]);
        }
    }, [])

    return (
        <div className='dashboard__page'>
            <Sidebar />
            <div className="dashboard__content" id="hirek">
                <Toaster />
                <h1>Hírek</h1>
                <div className="card">
                    <div className="card__head">
                        <h3>Hírek</h3>
                    </div>
                    <div className="card__body">
                        <DataTable data={Hirek} columns={columns} expandableRows expandOnRowClicked expandableRowsComponent={DetailedNews} pagination highlightOnHover />

                        <Link to="create" className='btn bg-primary mb-2'><IoAddCircleSharp className='btn-icon' /> Létrehozás</Link>
                    </div>
                    

                </div>
            </div>
        </div>
    )
}

const DetailedNews = ({data}) => {
    const Remove = () => {
        if(window.confirm(`Biztos, hogy törli a kiválasztott hírt? (#${data.id})`)) {
            
            toast.promise(DeleteNews(data.id), {
                loading: 'Bejegyzés törlése..',
                success: (data) =>  `Bejegyzés eltávolítva.`,
                error: 'Nem sikerült eltávolítani a kijelölt hírt.',
            })
        }
    };
    return (
        <div className="news__detail">
            <Link to={`/news/${data.url}`} target={"_blank"} className="news-btn bg-primary">Előnézet</Link>
            <Link to={`edit/${data.url}`} className="news-btn bg-warning">Szerkesztés</Link>
            
            <button onClick={Remove} className="btn bg-danger news-btn">Törlés</button>
        </div>
    );
};

export default News
