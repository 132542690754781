import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { CreateUser } from '../../Services/Authentication/Auth.inc';
import './Users.css';

const AddUser = (props) => {

    const [email, setEmail] = useState("");
    const [nev, setNev] = useState("");
    const [passwd, setPasswd] = useState("");
    const [passwd2, setPasswd2] = useState("");
    

    const Submit = (e) => {
        e.preventDefault();

        if(!passwd) {
            toast.error("A jelszó nem maradhat üresen.");
            
            return;
        }

        if(passwd !== passwd2) { 
            toast.error("A két jelszó nem egyezik meg.");
            return;
        }

        toast.promise(CreateUser(email, nev, passwd), {
            loading: 'Felhasználó létrehozása folyamatban...',
            success: "Felhasználó létrehozva.",
            error: "Nem sikerült létrehozni a felhasználót."
        })
    }
    
  return <div id={`language__modal`} className={`${props.isShown && "show__lang"}`} onClick={props.onClose}>
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          <div className="modal__head">
              <h3>Felhasználó létrehozása</h3>
              <div className="close" onClick={props.onClose}>&times;</div>
          </div>
          
          <div className="modal__body">
              <form onSubmit={Submit}>
                  <div className="input-group">
                      <input type="email" className="form-control form-sm mb-2" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='E-mail cím' />
                      <input type="text" className="form-control form-sm mb-2" value={nev} onChange={(e) => setNev(e.target.value)} required placeholder='Név' />
                      <input type="password" className="form-control form-sm mb-2" value={passwd} onChange={(e) => setPasswd(e.target.value)} required placeholder='Jelszó' />
                      <input type="password" className="form-control form-sm mb-2" value={passwd2} onChange={(e) => setPasswd2(e.target.value)} required placeholder='Jelszó újra' />
                  </div>

                  <button className="btn bg-primary" type='submit'>Létrehozás</button>
              </form>
          </div>
      </div>
  </div>;
};

export default AddUser;
