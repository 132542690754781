import React from 'react';
import './LanguageModal.css';
import HUFlag from '../../Assets/hu.png';
import ENFlag from '../../Assets/en.png';
import SKFlag from '../../Assets/sk.png';

const LanguageModal = (props) => {
    
    const ChangeLanguage = (lang) => {
        localStorage.setItem("ita-sc-lang", lang);
        window.location.reload();
        props.onClose();
    };
    
  return <div id={`language__modal`} className={`${props.isShown && "show__lang"}`} onClick={props.onClose}>
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          <div className="modal__head">
              
              <div className="close" onClick={props.onClose}>&times;</div>
          </div>
          

          
          <div className="modal__body">
          <h3 className='text-center welcome'><img src={HUFlag} alt="HU:" /> Üdvözüljük az Okos Közösségek Virtuális Helpdesk Platformján!</h3>
          <h3 className='text-center welcome'><img src={ENFlag} alt="EN:" /> Welcome to the Smart Communities Virtual Helpdesk Platform!</h3>
          <h3 className='text-center welcome'><img src={SKFlag} alt="SK:" /> Vitajte na platforme virtuálneho asistenčného pracoviska Inteligentné Komunity!</h3>
            <hr className='sep' />
            <h4 className='text-center choose'>Válasszon nyelvet / Choose language / Vyberte jazyk</h4>
              <div className="langs__container">
                  <div className="language" onClick={() => ChangeLanguage("hu")}>
                      <img src={HUFlag} alt="Magyar" />
                      <p>Magyar</p>
                  </div>
                  <div className="language" onClick={() => ChangeLanguage("en")}>
                      <img src={ENFlag} alt="English" />
                      <p>English</p>
                  </div>
                  <div className="language" onClick={() => ChangeLanguage("sk")}>
                      <img src={SKFlag} alt="Slovenský" />
                      <p>Slovenský</p>
                  </div>
              </div>
          </div>
      </div>
  </div>;
};

export default LanguageModal;
