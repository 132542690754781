import axios from "axios"
import { API_URL } from "../Includes/backend.config";


export default async function Request (type = "GET", target, data = '', headers = {'Content-Type': 'application/json'}) {
    const res = 
        type === "POST" 
            ? await axios.post(`${API_URL}/Routes.php?target=${target}`, JSON.stringify(data), { headers: headers, withCredentials: false })
            : await axios.get(`${API_URL}/Routes.php?target=${target}`, { headers: headers, withCredentials: false })

    console.log(res.data)
    return res.data?.response;
};

export const AddDoc = async (author, title, url, intro, category, lang, files, desc) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=course/create`, JSON.stringify({uid: author, title, url, intro, category, lang, files, desc}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    console.log(res.data)
    return res.data?.response;
};

export const GetDocs = async () => {

    const res = await axios.get(`${API_URL}/Routes.php?target=course/get`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,
    });

    return res.data?.response;
}
export const GetDoc = async (id) => {

    const res = await axios.get(`${API_URL}/Routes.php?target=course/get&id=${id}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,
    });

    return res.data?.response;
}

export const DelDoc = async (id) => {
    const res = await axios.get(`${API_URL}/Routes.php?target=course/del&id=${id}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });

    return res.data?.response;
}


export const UploadDocsFile = async (file, id, name) => {
    const res = await axios.post(`${API_URL}/functions/docfile.php?id=${id}&name=${name}`, file, 
    {
        headers: {
            'Content-Type' : 'multipart/form-data'
        },
        withCredentials: false,

    });

    console.log(res.data);

    return res.data?.response;
}
export const DelDocsFile = async (file, id = 0) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=file/delete&course_id=${id}`, JSON.stringify({file}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });

    console.log(res.data);

    return res.data?.response;
}