export const DateFormatter = (d) => {

    if(!d) return; 
    
    let dates = d.split('-');
    let t = dates[2].split(' ');
    let times = t[1].split(':');

    console.log(Number(dates[1]));

    const honapok = ["január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"]

    return `${dates[0]}. ${honapok[Number(dates[1])-1]} ${t[0]}. ${times[0]}:${times[1]}`;
    

    
};

export default DateFormatter;