import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Impresszum = () => {
    return (
        <>
        <Helmet>
            <title>Adatvédelmi nyilatkozat - Smart Communities Platform</title>
        </Helmet>
            <Navbar />
            <div id="adatvedelmi">
                <div className="site__head">
                    <div className="container">
                        <h1 className="site__title">Impresszum</h1>
                    </div>
                    
                </div>
                <div className="container">
                    <div className="site__container">
                        <div className="site__content">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet dolor aliquid quo impedit enim praesentium debitis nemo voluptas vero, eos voluptatum totam labore tempore deleniti doloribus officia consequatur similique maiores.</p>
                        </div>
                        <div className="site__widgets">

                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    )
}

export default Impresszum
