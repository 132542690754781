import React, { useEffect, useState } from 'react'
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { Helmet } from 'react-helmet'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import GetTranslation from '../../Language';
import { GetNumLanguage, GetLanguage } from '../../Services/Includes/Language';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import styled, {keyframes} from 'styled-components';
import '../../Globals.css'

const titles = [
    'Main Results',
    'Ranking',
    'Detailed Results',

    'Hlavné výsledky',
    'Rebríček',
    'Podrobné výsledky',
    
];



const SingleSR = () => {
    const {id} = useParams();
    const [active, setActive] = useState(null);
    useEffect(() => {

        switch(id) {
            case 'main-results':
                setActive(0);
                break;
            case 'ranking':
                setActive(1);
                break;
            case 'detailed-results':
                setActive(2);
                break;
            case 'hlavne-vysledky':
                setActive(3);
                break;
            case 'rebricek':
                setActive(4);
                break;
            case 'podrobne-vysledky':
                setActive(5);
                break;
                
            default: setActive(0); break;

        }

    }, [id]);

  return (
    <>
    <Helmet>
        <title>{`${titles[active]}`} - Smart Communities Platform</title>
    </Helmet>
    <Navbar />

    <div id="doc">
        <div className="site__head">
            <div className="container">
                <h1 className="site__title">{`${titles[active]}`}</h1>
            </div>
                    
        </div>
        <div className="container">
            <div className="site__container">

            
                <div className="site__content">
                    {(active === 0 || active === 3) && <MainResults />}
                    {(active === 1 || active === 4) && <Ranking />}
                    {(active === 2 || active === 5) && <Detailed />}
                </div>
            </div>
        </div>
    </div>

    <Footer />
    </>
  )
}
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 3px solid grey;
	border-right: 3px solid grey;
	border-bottom: 3px solid grey;
	border-left: 3px solid black;
	background: transparent;
	width: 35px;
	height: 35px;
	border-radius: 50%;
`;
const CustomLoader = () => (
    <div style={{ padding: '24px' }}>
        <Spinner />
    </div>
);

const MainResults = () => {

    const EN = `<p id="isPasted"><strong>Ranking of smart municipalities</strong> is made using a composite index that compares the prosperity of municipalities in Slovakia as their attractiveness for the life of the current inhabitants. However, building prosperity requires commitment and long-term work for the benefit of the quality of life of residents.&nbsp;</p>

    <p>The total number of municipalities in Slovakia is high, up to 2927, ranging from municipalities with a few dozen to cities with up to 100,000 inhabitants. The main focus is on smart municipalities (communities) and therefore Slovakia&#39;s two largest cities - Bratislava and Ko&scaron;ice - are excluded from the comparison.</p>
    
    <p>&nbsp;</p>
    
    <p>Municipalities build prosperity for this and future generations. So which municipalities are particularly smart in that they are able to retain current residents and attract new ones due to their attractiveness and promising prosperity for the future? Obviously there is no single element that defines which communities are more smart compared to others. However, we can define the core dimensions that together make up an assessment of a smart, prosperous community - human capital, housing and infrastructure, economy and employment, and civic participation. The fifth dimension explains the trend, the dynamics of change over the past 10 years. The dimensions include indicators that are in different units, so conversion is used by scaling to dimensionless units, making all values on a scale from 0 to a maximum of 100. The higher the value, the better the situation for the indicator under study. The values are then recalculated available for each dimension separately, but also in the overall, composite index of smart municipalities.&nbsp;</p>
    
    <p>It is clear that each municipality has different conditions in terms of its location or size. The external environment for municipalities is more favourable in the proximity of larger centres, especially Bratislava. And also with a larger population the municipality is more competitive. Therefore, the basic results are published alphabetically and not in overall order.&nbsp;</p>
    `;

    const SK = `<p id="isPasted"><strong>Rebr&iacute;ček prosperuj&uacute;cich (smart) obc&iacute;</strong> je zostaven&yacute; pomocou kompozitn&eacute;ho indexu, ktor&yacute; umožňuje porovnať prosperitu obc&iacute; na Slovensku ako ich atraktivitu pre život s&uacute;časn&yacute;ch obyvateľov. Budovanie prosperity si v&scaron;ak vyžaduje odhodlanie a dlhodob&uacute; pr&aacute;cu v prospech kvality života obyvateľov.&nbsp;</p>

    <p>Celkov&yacute; počet obc&iacute; na Slovensku je vysok&yacute;, až 2927, od obc&iacute; s niekoľk&yacute;mi desiatkami obyvateľov až po mest&aacute; s počtom obyvateľov do 100 000. Hlavn&aacute; pozornosť je venovan&aacute; prosperuj&uacute;cim obciam (komunit&aacute;m), a preto s&uacute; z porovnania vyl&uacute;čen&eacute; dve najv&auml;č&scaron;ie slovensk&eacute; mest&aacute; - Bratislava a Ko&scaron;ice.</p>
    
    <p>Obce buduj&uacute; prosperitu pre t&uacute;to a bud&uacute;ce gener&aacute;cie. Ktor&eacute; obce s&uacute; teda mimoriadne inteligentn&eacute; v tom zmysle, že si dok&aacute;žu udržať s&uacute;časn&yacute;ch obyvateľov a pril&aacute;kať nov&yacute;ch vďaka svojej atraktivite a pr&iacute;sľubu prosperity do bud&uacute;cnosti? Je zrejm&eacute;, že neexistuje jedin&yacute; prvok, ktor&yacute; by určoval, ktor&eacute; obce s&uacute; v porovnan&iacute; s in&yacute;mi inteligentnej&scaron;ie. M&ocirc;žeme v&scaron;ak definovať z&aacute;kladn&eacute; dimenzie, ktor&eacute; spoločne tvoria hodnotenie inteligentnej a prosperuj&uacute;cej komunity - ľudsk&yacute; kapit&aacute;l, b&yacute;vanie a infra&scaron;trukt&uacute;ra, hospod&aacute;rstvo a zamestnanosť a občianska particip&aacute;cia. Piaty rozmer vysvetľuje trend, dynamiku zmien za posledn&yacute;ch 10 rokov. Dimenzie obsahuj&uacute; ukazovatele, ktor&eacute; s&uacute; v r&ocirc;znych jednotk&aacute;ch, preto sa použ&iacute;va prepočet pomocou &scaron;k&aacute;lovania na bezrozmern&eacute; jednotky, č&iacute;m sa v&scaron;etky hodnoty nach&aacute;dzaj&uacute; na stupnici od 0 do maxim&aacute;lne 100. Č&iacute;m vy&scaron;&scaron;ia je hodnota, t&yacute;m lep&scaron;ia je situ&aacute;cia pre sk&uacute;man&yacute; ukazovateľ. Hodnoty sa potom prepoč&iacute;taj&uacute; pre každ&uacute; dimenziu osobitne, ale aj v celkovom, zloženom indexe inteligentn&yacute;ch obc&iacute;.&nbsp;</p>
    
    <p>Je zrejm&eacute;, že každ&aacute; obec m&aacute; in&eacute; podmienky z hľadiska svojej polohy alebo veľkosti. Vonkaj&scaron;ie prostredie pre obce je priaznivej&scaron;ie v bl&iacute;zkosti v&auml;č&scaron;&iacute;ch centier, najm&auml; Bratislavy. A tiež pri v&auml;č&scaron;om počte obyvateľov je obec konkurencieschopnej&scaron;ia. Preto s&uacute; z&aacute;kladn&eacute; v&yacute;sledky zverejnen&eacute; podľa abecedy a nie v celkovom porad&iacute;.&nbsp;</p>
    `;


    const columns = [
        {
            name: 'MUNICIPALITY',
            selector: row => row.MUNICIPALITY,
            sortable: true,
            scrollX: true,
            cell: row => <p className='fullp'>{row.MUNICIPALITY}</p>,
            maxWidth: '300px',
            minWidth: '200px',
            
        },
        {
            name: 'DISTRICT',
            selector: row => row.DISTRICT,
            sortable: true,
            cell: row => <p className='fullp'>{row.DISTRICT}</p>,

            maxWidth: '300px',
            minWidth: '200px'

        },
        {
            name: 'REGION',
            selector: row => row.REGION,
            cell: row => <p className='fullp'>{row.REGION}</p>,

            sortable: true,
            maxWidth: '300px',
            minWidth: '200px'
        },
        {
            name: 'DIMENSION HUMAN CAPITAL',
            selector: row => row.DIMENSION_HUMAN_CAPITAL,
            sortable: true,
            cell: row => row.DIMENSION_HUMAN_CAPITAL.toFixed(2)
            
        },
        {
            name: 'RANK',
            selector: row => row.RANK1,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION INFRASTRUCTURE AND HOUSING',
            selector: row => row.DIMENSION_INFRASTRUCTURE_AND_HOUSING,
            sortable: true,
            cell: row => row.DIMENSION_INFRASTRUCTURE_AND_HOUSING.toFixed(2),
            
        },
        {
            name: 'RANK',
            selector: row => row.RANK2,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION ECONOMY AND EMPLOYMENT',
            selector: row => row.DIMENSION_ECONOMY_AND_EMPLOYMENT,
            sortable: true,
            cell: row => row.DIMENSION_ECONOMY_AND_EMPLOYMENT.toFixed(2)

        },
        {
            name: 'RANK',
            selector: row => row.RANK3,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION CIVIC PARTICIPATION',
            selector: row => row.DIMENSION_CIVIC_PARTICIPATION,
            sortable: true,
            cell: row => row.DIMENSION_CIVIC_PARTICIPATION.toFixed(2)

        },
        {
            name: 'RANK',
            selector: row => row.RANK4,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION DEVELOPMENT AND GROWTH',
            selector: row => row.DIMENSION_DEVELOPMENT_AND_GROWTH,
            sortable: true,
            cell: row => row.DIMENSION_DEVELOPMENT_AND_GROWTH.toFixed(2)

        },
        {
            name: 'RANK',
            selector: row => row.RANK5,
            sortable: true,
            maxWidth: "20px"
        },
    ];
    const [data, setData] = useState([]);
    const [pending, setPending] = React.useState(true);
   
    useEffect(() => {
        fetch("../data/smart-ranking/mainresults.json").then((data) => data.json()).then((r) => {
            console.log(r.length)
            setData(r);
            setPending(false);  
        });
    }, []);
    return (
        <>
        <div className="intro">
            <div dangerouslySetInnerHTML={{ __html: (GetLanguage() === "en" ? EN : SK)}}></div>
        </div>
        <div className='table__container pava1'>
            <DataTable columns={columns} data={data} pagination highlightOnHover paginationRowsPerPageOptions={[10, 20, 35, 50, 100, 200]} striped progressPending={pending} progressComponent={<CustomLoader />} />
        </div>
        </>
    );

};
const Ranking = () => {

    const EN = `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><strong><span style="color:black;">The ranking of smart municipalities</span></strong><span style="color:black;">&nbsp;is compiled using a composite index that compares the prosperity of municipalities in Slovakia as well as their attractiveness for the life of their inhabitants.&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.5cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">The basic dimensions that together constitute the assessment of a smart, prosperous municipality can be defined as follows:&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENSION 1 HUMAN CAPITAL&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENSION 2 INFRASTRUCTURE AND HOUSING&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENSION 3 ECONOMY AND EMPLOYMENT&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENSION 4 CIVIC PARTICIPATION&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.5cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENSION 5 DEVELOPMENT AND GROWTH&nbsp; &nbsp; &nbsp; &nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">When reading the results, it is important to take into account that municipalities vary in size and their situation is influenced by which region they are located in. Therefore, municipalities in the Bratislava region have a much better disposition for building prosperity, due to regional differences in Slovakia.</span></p>`;

    const SK = `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><strong><span style="color:black;">Rebr&iacute;ček inteligentn&yacute;ch obc&iacute;</span></strong><span style="color:black;">&nbsp;je zostaven&yacute; pomocou kompozitn&eacute;ho indexu, ktor&yacute; porovn&aacute;va prosperitu obc&iacute; na Slovensku ako ich atraktivitu pre život obyvateľov.&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.5cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">Z&aacute;kladn&eacute; dimenzie, ktor&eacute; spoločne tvoria hodnotenie inteligentnej, prosperuj&uacute;cej obce, m&ocirc;žeme definovať nasledovne:&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENZIA 1 ĽUDSK&Yacute; KAPIT&Aacute;L&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENZIA 2 INFRA&Scaron;TRUKT&Uacute;RA A B&Yacute;VANIE&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENZIA 3 HOSPOD&Aacute;RSTVO A ZAMESTNANOSŤ&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENZIA 4 OBČIANSKA PARTICIP&Aacute;CIA&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:.5cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">DIMENZIA 5 ROZVOJ A RAST&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style='font-size:16px;font-family:"Times New Roman",serif;'>&nbsp;</span></p>
    <p style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px;'><span style="color:black;">Pri č&iacute;tan&iacute; v&yacute;sledkov je potrebn&eacute; vziať do &uacute;vahy, že obce maj&uacute; r&ocirc;znu veľkosť a ich situ&aacute;ciu ovplyvňuje v ktorom kraji sa nach&aacute;dzaj&uacute;. Preto oveľa lep&scaron;iu dispoz&iacute;ciu pre budovanie prosperity maj&uacute; obce v Bratislavskom kraji, kv&ocirc;li region&aacute;lnym rozdielom na Slovensku.</span></p>`;

    const columns = [
        {
            name: 'MUNICIPALITY',
            selector: row => row.MUNICIPALITY,
            sortable: true,
            scrollX: true,
            cell: row => <p className='fullp'>{row.MUNICIPALITY}</p>,
            maxWidth: '300px',
            minWidth: '200px'
        },
        {
            name: 'DISTRICT',
            selector: row => row.DISTRICT,
            sortable: true,
            cell: row => <p className='fullp'>{row.DISTRICT}</p>,

            maxWidth: '300px',
            minWidth: '200px'

        },
        {
            name: 'REGION',
            selector: row => row.REGION,
            cell: row => <p className='fullp'>{row.REGION}</p>,

            sortable: true,
            maxWidth: '300px',
            minWidth: '200px'
        },
        {
            name: 'INDEX SMART MUNICIPALITIES',
            selector: row => row.INDEX_SMART_MUNICIPALITIES.toFixed(2),
            sortable: true,
        },
        {
            name: 'RANKING',
            selector: row => row.RANKING,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION HUMAN CAPITAL',
            selector: row => row.DIMENSION_HUMAN_CAPITAL,
            sortable: true,
            cell: row => row.DIMENSION_HUMAN_CAPITAL.toFixed(2)
            
        },
        {
            name: 'RANK',
            selector: row => row.RANK1,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION INFRASTRUCTURE AND HOUSING',
            selector: row => row.DIMENSION_INFRASTRUCTURE_AND_HOUSING,
            sortable: true,
            cell: row => row.DIMENSION_INFRASTRUCTURE_AND_HOUSING.toFixed(2),
            
        },
        {
            name: 'RANK',
            selector: row => row.RANK2,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION ECONOMY AND EMPLOYMENT',
            selector: row => row.DIMENSION_ECONOMY_AND_EMPLOYMENT,
            sortable: true,
            cell: row => row.DIMENSION_ECONOMY_AND_EMPLOYMENT.toFixed(2)

        },
        {
            name: 'RANK',
            selector: row => row.RANK3,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION CIVIC PARTICIPATION',
            selector: row => row.DIMENSION_CIVIC_PARTICIPATION,
            sortable: true,
            cell: row => row.DIMENSION_CIVIC_PARTICIPATION.toFixed(2)

        },
        {
            name: 'RANK',
            selector: row => row.RANK4,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'DIMENSION DEVELOPMENT AND GROWTH',
            selector: row => row.DIMENSION_DEVELOPMENT_AND_GROWTH,
            sortable: true,
            cell: row => row.DIMENSION_DEVELOPMENT_AND_GROWTH.toFixed(2)

        },
        {
            name: 'RANK',
            selector: row => row.RANK5,
            sortable: true,
            maxWidth: "20px"
        },
    ];
    const [data, setData] = useState([]);
    const [pending, setPending] = React.useState(true);
   
    useEffect(() => {
        fetch("../data/smart-ranking/ranking.json").then((data) => data.json()).then((r) => {
            console.log(r.length)
            setData(r);
            setPending(false);  
        });
    }, []);
    return (
        <>
        <div className="intro">
            <div dangerouslySetInnerHTML={{ __html: (GetLanguage() === "en" ? EN : SK)}}></div>
        </div>
        <div className='table__container pava2'>
            <DataTable columns={columns} data={data} pagination highlightOnHover paginationRowsPerPageOptions={[10, 20, 35, 50, 100, 200]} striped progressPending={pending} progressComponent={<CustomLoader />} />
        </div>
        </>
    );

};
const Detailed = () => {

    const EN = `<p class="MsoNormalM"><b><span>The
    ranking of smart municipalities</span></b><span> is compiled using a
    composite index. Together, the core dimensions make up the ranking of a smart
    and prosperous municipality as follows: </span></p>
    <p>DIMENSION 1 HUMAN CAPITAL </p>
    <p>DIMENSION 2 INFRASTRUCTURE AND HOUSING </p>                                                              
    <p>DIMENSION 3 ECONOMY AND EMPLOYMENT</p>
    <p>DIMENSION 4 CIVIC PARTICIPATION</p>                                               
    <p>DIMENSION 5 DEVELOPMENT AND GROWTH</p>      
    <p class="MsoNormalM"><span>The first four dimensions explain the
    situation in 2021 and the fifth dimension explains the trend, the dynamics of
    change over the last 10 years. The dimensions contain indicators that are in
    different units, so conversion is used by scaling to dimensionless units,
    whereby all values are on a scale from 0 to a maximum of 100. The higher the
    value, the better the situation for the indicator under consideration. The
    values are then recalculated available for each dimension separately, but also
    in an overall, composite index of smart municipalities. </span></p>
    <p class="MsoNormalM"><span>Obviously, each municipality has different
    conditions in terms of its location or size. The external environment for
    municipalities is more favourable in the proximity of larger centres,
    especially Bratislava. And also with a larger population, the municipality is
    more competitive. Therefore, the detailed results are published alphabetically
    and not in overall order. </span></p>
    `;

    const SK = `<p class="MsoNormalM"><b><span>Poradie
    inteligentných</span></b><span> obcí sa zostavuje pomocou kompozitného
    indexu. Základné dimenzie spolu tvoria hodnotenie inteligentnej a prosperujúcej
    obce takto: </span></p>
    <p>DIMENZIA 1 ĽUDSKÝ KAPITÁL</p>					
    <p>DIMENZIA 2 INFRAŠTRUKTÚRA A BÝVANIE</p>				
    <p>DIMENZIA 3 HOSPODÁRSTVO A ZAMESTNANOSŤ</p> 
    <p>DIMENZIA 4 OBČIANSKA PARTICIPÁCIA</p>				
    <p>DIMENZIA 5 ROZVOJ A RAST</p>

    <p class="MsoNormalM"><span>&nbsp;</span></p>
    <p class="MsoNormalM"><span>Prvé štyri dimenzie vysvetľujú stav v roku
    2021 a piata dimenzia vysvetľuje trend, dynamiku zmien za posledných 10 rokov.
    Dimenzie obsahujú ukazovatele, ktoré sú v rôznych jednotkách, preto sa používa
    prepočet pomocou škálovania na bezrozmerné jednotky, čím sa všetky hodnoty
    nachádzajú na stupnici od 0 do maximálne 100. Čím vyššia je hodnota, tým lepšia
    je situácia pre skúmaný ukazovateľ. Hodnoty sa potom prepočítajú pre každú dimenziu
    osobitne, ale aj v celkovom, zloženom indexe inteligentných obcí. </span></p>
    <p class="MsoNormalM"><span>Je zrejmé, že každá obec má iné podmienky z
    hľadiska svojej polohy alebo veľkosti. Vonkajšie prostredie pre obce je
    priaznivejšie v blízkosti väčších centier, najmä Bratislavy. A tiež pri väčšom
    počte obyvateľov je obec konkurencieschopnejšia. Preto sú detailné výsledky
    zverejnené podľa abecedy a nie v celkovom poradí. </span></p>
    `;

    const columns = [
        {
            name: 'MUNICIPALITY',
            selector: row => row.MUNICIPALITY,
            sortable: true,
            scrollX: true,
            cell: row => <p className='fullp'>{row.MUNICIPALITY}</p>,
            maxWidth: '300px',
            minWidth: '200px'
        },
        {
            name: 'DISTRICT',
            selector: row => row.DISTRICT,
            sortable: true,
            cell: row => <p className='fullp'>{row.DISTRICT}</p>,

            maxWidth: '300px',
            minWidth: '200px'

        },
        {
            name: 'REGION',
            selector: row => row.REGION,
            cell: row => <p className='fullp'>{row.REGION}</p>,

            sortable: true,
            maxWidth: '300px',
            minWidth: '200px'
        },
        {
            name: 'Population 2011',
            selector: row => row.Population_2011,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'Population 2021',
            selector: row => row.Population_2021,
            sortable: true,
            maxWidth: "20px"
        },
        {
            name: 'Pre-productive age population 0-14 years (%) 2021',
            selector: row => row.Pre_productive_age_population_0_14_years_2021.toFixed(2),
            sortable: true,
        },
        {
            name: 'Population with full secondary education (%) 2021',
            selector: row => row.Population_with_ful__secondary_education_2021.toFixed(2),
            sortable: true,
        },
        {
            name: 'Population with higher education (%) 2021',
            selector: row => row.Population_with_higher_education__2021.toFixed(2),
            sortable: true,
        },
        {
            name: 'Population growth 2021/2011',
            selector: row => row.Population_growth_2021_2011.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 1 TOTAL VALUE',
            selector: row => row.DIMENSION_1_TOTAL_VALUE.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMESNION RANKING',
            selector: row => row.DIMESNION__RANKING,
            sortable: true,
        },
        {
            name: 'Water connection from the public network or own  2021 (%)',
            selector: row => row.Water_connection_from_the_public_network_or_own__2021_.toFixed(2),
            sortable: true,
        },
        {
            name: 'Gas connection 2021 (%)',
            selector: row => row.Gas_connection_2021_.toFixed(2),
            sortable: true,
        },
        {
            name: '2021_vykurovanie pevné palivo (%)',
            selector: row => row._2021_vykurovanie_pevné_palivo_.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 2 TOTAL VALUE',
            selector: row => row.DIMENSION_2_TOTAL_VALUE.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 2 RANKING',
            selector: row => row.DIMENSION_2_RANKING,
            sortable: true,
        },
        {
            name: 'Unemployment rate 2020 (%)',
            selector: row => row.Unemployment_rate_2020_.toFixed(2),
            sortable: true,
        },
        {
            name: 'Population of post-working age 65 and over 2021 (%)',
            selector: row => row.Population_of_post_working_age_65_and_over_2021_.toFixed(2),
            sortable: true,
        },
        {
            name: 'Population per legal entity',
            selector: row => row.Population_per_legal_entity.toFixed(2),
            sortable: true,
        },
        {
            name: 'Municipality current and capital income per capita 2020',
            selector: row => row.Municipality_current_and_capital_income_per_capita_2020.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 3 TOTAL VALUE',
            selector: row => row.DIMENSION_3_TOTAL_VALUE.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 3 RANKING',
            selector: row => row.DIMENSION_3_RANKING,
            sortable: true,
        },
        {
            name: 'Participation in local elections 2018 (%)',
            selector: row => row.Participation_in_local_elections_2018_.toFixed(2),
            sortable: true,
        },
        {
            name: 'Participation in parliamentary local elections 2020 (%)',
            selector: row => row.Participation_in_parliamentary_local_elections_2020_.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 4 TOTAL VALUE',
            selector: row => row.DIMENSION_4_TOTAL_VALUE_.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 4 RANKING',
            selector: row => row.DIMENSION_4_RANKING,
            sortable: true,
        },
        {
            name: 'Ageing index change 2020 to 2011',
            selector: row => row.Ageing_index_change_2020_to_2011.toFixed(2),
            sortable: true,
        },
        {
            name: 'Number of legal entities total change 2020 to 2011',
            selector: row => row.Number_of_legal_entities_total_change_2020_to_2011.toFixed(2),
            sortable: true,
        },
        {
            name: 'Unemployment rate change 2020 to 2010',
            selector: row => row.Unemployment_rate_change_2020_to_2010.toFixed(2),
            sortable: true,
        },
        {
            name: 'Change in municipal budget income 2020 to 2010',
            selector: row => row.Change_in_municipal_budget_income_2020_to_2010.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 5 TOTAL VALUE',
            selector: row => row.DIMENSION_5_TOTAL_VALUE.toFixed(2),
            sortable: true,
        },
        {
            name: 'DIMENSION 5 RANKING',
            selector: row => row.DIMENSION_5_RANKING,
            sortable: true,
        },
        
        
    ];

    const [data, setData] = useState([]);
    const [pending, setPending] = React.useState(true);
   
    useEffect(() => {
        fetch("../data/smart-ranking/detailed.json").then((data) => data.json()).then((r) => {
            setData(r);
            setPending(false);  
        });
    }, []);
    console.log("LANG: ", GetLanguage())
    return (
        <>
        <div className="intro">
            <div dangerouslySetInnerHTML={{ __html: (GetLanguage() === "en" ? EN : SK)}}></div>
        </div>
        <div className='table__container pava'>
            <DataTable columns={columns} data={data} pagination highlightOnHover paginationRowsPerPageOptions={[10, 20, 35, 50, 100, 200]} striped progressPending={pending} progressComponent={<CustomLoader />} />
        </div>
        </>
    );

};


export default SingleSR