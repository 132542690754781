import React from 'react'
import '../Dashboard.css';
import './Sidebar.css';
import Logo from  '../../Assets/image/projektlogo.png';
import { Link, useLocation } from 'react-router-dom';
import {IoDocumentsSharp, IoLayers, IoLogOut, IoPeopleSharp, IoSettingsSharp, IoPersonSharp} from 'react-icons/io5'
import { BsNewspaper } from 'react-icons/bs'

import toast, {Toaster} from 'react-hot-toast';

const Sidebar = () => {
    const location = useLocation();
    const Logout = () => {
        localStorage.removeItem('ita-login');
        //#console.log("Logged out")
        toast.success("Sikeres kijelentkezés!");
        window.location.reload();
    };
    return (
        <div id="sidebar">
            <Toaster />
            <div className="sidebar__head">
                <img src={Logo} alt="ITA - Smart Communities" />
            </div>
            <div className="sidebar__pages">
                {/*<div className="sidebar__section">
                    <h5 className="section__title">Bejegyzések</h5>
                    
      
                </div>*/}
                {/*<Link to="/dash" className={`page ${location.pathname === '/dash' && 'active__page'}`}><BiStats className='page__icon' /> Statisztika</Link>*/}
                <Link to="/dash/news" className={`page ${location.pathname === '/dash/news' && 'active__page'}`}><BsNewspaper className='page__icon' /> Hírek</Link>
                {<Link to="/dash/docs" className={`page ${location.pathname === '/dash/docs' && 'active__page'}`}><IoDocumentsSharp className='page__icon' /> Dokumentumok</Link>}
                {/*{<Link to="/dash/mm" className={`page ${location.pathname === '/dash/mm' && 'active__page'}`}><IoDocumentsSharp className='page__icon' /> Match-making adatbázis</Link>}
                {<Link to="/dash/prez" className={`page ${location.pathname === '/dash/prez' && 'active__page'}`}><IoDocumentsSharp className='page__icon' /> Prezentációk</Link>}*/}

                <Link to="/dash/courses" className={`page ${location.pathname === '/dash/courses' && 'active__page'}`}><IoLayers className='page__icon'/> Kurzusok</Link>
                <Link to="/dash/users"className={`page ${location.pathname === '/dash/users' && 'active__page'}`}><IoPeopleSharp className='page__icon' /> Felhasználók</Link>
                <Link to="/dash/settings" className={`page ${location.pathname === '/dash/settings' && 'active__page'}`}><IoSettingsSharp className='page__icon' /> Beállítások</Link>
                <p className='username'><IoPersonSharp style={{marginRight: '5px'}} /> {JSON.parse(localStorage.getItem("ita-login")).nev}</p>
                <button className='logout-btn' onClick={Logout}><IoLogOut className='page__icon' /> Kijelentkezés</button>

                

            </div>
        </div>
    )
}

export default Sidebar
