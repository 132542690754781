import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { GetLanguage } from '../../Services/Includes/Language'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Adatvedelmi = () => {
    const [content, setContent] = useState([]);
    useEffect(() => {
        axios.get("data/adatvedelmi.json").then((e) => {
            setContent(e.data.find(x=>x.lang === GetLanguage()))
           //console.log(e.data.find(x=>x.lang === GetLanguage()))
        });
    }, []);
    return (
        <>
        <Helmet>
            <title>{`${content.title}`} - Smart Communities Platform</title>
        </Helmet>
            <Navbar />
            <div id="adatvedelmi">
                <div className="site__head">
                    <div className="container">
                        <h1 className="site__title">{`${content.title}`}</h1>
                    </div>
                    
                </div>
                <div className="container">
                    <div className="site__container">
                        <div className="site__content">
                            <p>Weboldalunkat felkeresők weboldalunkat személyes adatok megadása nélkül látogathatják.</p>
                            <p>A honlap látogatója hozzájárul ahhoz, hogy a látogatással átadott adatait az adatkezelő a jogszabályokban, valamint a jelen tájékoztatóban foglaltak szerint kezelje, feldolgozza.</p>
                            <p>A minél magasabb színvonalú működés biztosítása és a felhasználói élmény növelése érdekében weboldalunkon „cookie”-kat használunk. A cookiet számítógépéről bármikor törölheti, illetve beállíthatja böngészőjét úgy, hogy a cookiek alkalmazását tiltsa. A cookiek alkalmazásának tiltásával azonban tudomásul veszi, hogy cookie nélkül az oldal működése nem lesz teljes értékű. </p>
                            <p> A honlapon a számítógépek IP-címe naplózásra kerülnek, hogy rögzítsék a felhasználó látogatását. Ezekből az adatokból statisztikákat készítünk, például annak megállapítása céljából, hogy a webhelyet milyen gyakran látogatják a felhasználók és esetenként mennyi időt töltenek ott. Az IP-címeket vállalkozásunk semmilyen olyan más adathoz nem köti, amely alapján az érintett személyesen azonosítható lenne, az adatok kizárólag statisztikai célokat szolgálnak. Ezen túlmenően sem a cookiekban, sem adatbázisban nem tárolunk a felhasználóról semmilyen személyes információt.  Az adatkezelés időtartama az átmeneti cookie-k esetén a honlap látogatásának befejezéséig tart. </p>
                            <p>A weboldalon található külső hivatkozás letöltésére az újonnan felkeresett honlap adatvédelmi tájékoztatója vonatkozik.</p>
                        </div>
                        <div className="site__widgets">

                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    )
}

export default Adatvedelmi
