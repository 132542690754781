import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import GetTranslation from '../../Language';
import { GetLanguage } from '../../Services/Includes/Language';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';

import KezikonyvIMG from '../../Assets/image/docs/kezikonyv.png';
import OkosIMG from '../../Assets/image/docs/okosfalu.png';
import Request from '../../Services/Documents/DocHandler';

const ChoosePres = () => {

    const [docs, setDocs] = useState([]);

    useEffect(() => {

        Request("GET", "docs/get").then((r) => {
            //console.log(r)
            r.forEach((item) => {
                //console.log(item?.lang, GetLanguage())
                if(item?.lang === GetLanguage() && Number(item.category) === 3) {
                    setDocs(state => [...state, item]);
                }
            });
        })

        return () => setDocs([]);
    }, []);

  return <>
    <Helmet>
        <title>{`${GetTranslation("prezentaciok")}`}- Smart Communities Platform</title>
    </Helmet>
    <Navbar />
    <div id="docs">
        <div className="container">
            <div className="docs__head">
                <h1 className="title">{GetTranslation("prezentaciok")}</h1>
            </div>
            <div className="docs__wrapper">
                <div className="docs__content">
                 <div className="docs__container">
                        <div className="doc__content">
                        {docs.map((item, i) => (
                            <Link to={`/presentations/${item.url}`} key={`dynamic-docs-${i}`}>
                                <div className='doc'>
                                                        
                                    {item.icon_url && <img src={item.icon_url} alt={item.title} className="doc__icon" />}
                                    <p className="doc__name">{item.title}</p>
                                </div>
                            </Link>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>;
};

export default ChoosePres;
