import React, { useRef, useState } from 'react';
import { UploadDocsFile } from '../../Services/Documents/DocHandler';
import './Documents.css';
import { IoFolderOpen, IoCloudUploadSharp } from 'react-icons/io5';

const AddPDF = (props) => {

    const [name, setName] = useState("");
    const fileUploader = useRef();
    const [fname, setFname] = useState("");

    const [file, setFile] = useState(null);
    

    const SetFile = async (e) => {
        e.preventDefault();
        const f = e.target.files[0];
        setFile(f);
        setFname(f.name);
        setName(f.name)
        

        /*toast.promise(UploadFile(formData, title).then((data) => setFiles(state => [...state, data])), {
            loading: <p>`${f.name} feltöltése folyamatban...`</p>,
            success: <p>`${f.name} feltöltve.`</p>
        });*/  

    };

    const Submit = (e) => {
        e.preventDefault();

        let formData = null;

        formData = new FormData();
        formData.append("file", file);
        

        /*Request('POST', `docs/add_file&id=${props.docId}&name=${name}`, formData).then((r) => {
            console.log(r)
        });*/
        UploadDocsFile(formData, props.docId, name).then((r) => {
            props.Update();
            props.onClose();
        });
        
    }
    
  return <div id={`language__modal`} className={`${props.isShown && "show__lang"}`} onClick={props.onClose}>
      <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          <div className="modal__head">
              <h3>Fájl feltöltése</h3>
              <div className="close" onClick={props.onClose}>&times;</div>
          </div>
          
          <div className="modal__body">
            <div className="upload_btn_container">
                <button className='btn bg-success mb-2' onClick={() => fileUploader.current?.click()}><IoFolderOpen className='btn-icon' /> Fájl kiválasztása</button>
                {fname && <small>Fájlnév: {fname}</small>}
            </div>
            
            <input type="file" ref={fileUploader} style={{display: 'none'}} onChange={SetFile} accept={".pdf"}  />
                
              <form onSubmit={Submit}>
                  <div className="input-group mb-4">
                      <input type="text" className="form-control form-sm " placeholder='Név' required value={name} onChange={(e) => setName(e.target.value)} />
                       <small>Milyen néven jelenjen meg a fájl?</small>
                    </div>
                    <button className="btn bg-primary" type='submit'><IoCloudUploadSharp className='btn-icon' /> Feltöltés</button>
              </form>
               
               
          </div>
      </div>
  </div>;
};

export default AddPDF;
