import axios from "axios"
import { API_URL } from "../Includes/backend.config";
import {GetLanguage} from '../Includes/Language'

export const CreateNews = async (title, content, uid, labels, url, lang) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=news/create`, JSON.stringify({title, content, uid, labels, url, lang}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    console.log(res.data);
    return res.data?.response;
}

export const GetNews = async () => {

    const res = await axios.get(`${API_URL}/Routes.php?target=news/get`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,
    });

    return res.data?.response;
}

export const GetNewsByLang = async () => {

    const res = await axios.get(`${API_URL}/Routes.php?target=news/get&lang=${GetLanguage()}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,
    });

    return res.data?.response;
}
export const GetSingleNews = async (id) => {

    const res = await axios.get(`${API_URL}/Routes.php?target=news/get&id=${id}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,
    });

    console.log(res.data)

    return res.data?.response;
}

export const ModifyNews = async (title, content, id, labels, lang) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=news/edit`, JSON.stringify({title, content, id, labels, lang}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });

    console.log(res.data.response);

    return res.data?.response;
}
export const DeleteNews = async (id) => {
    const res = await axios.get(`${API_URL}/Routes.php?target=news/del&id=${id}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });

    return res.data?.response;
}



