import axios from "axios"
import { API_URL } from "../Includes/backend.config";

export const Login = async (email, passwd) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=auth/login`, JSON.stringify({email: email, passwd: passwd}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    if(res.data.response?.length > 0) {
        localStorage.setItem('ita-login', JSON.stringify(res.data.response[0]));
    }
    return res.data?.response;
}
export const Reg = async (token, nev, passwd) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=auth/reg`, JSON.stringify({token, nev: nev, passwd: passwd}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    
    console.log(res.data);
    return res.data?.response;
}

export const isLoggedIn = () => {
    //#console.log(typeof localStorage.getItem('ita-login') !== "undefined" )
    return typeof localStorage.getItem('ita-login') !== "undefined" && localStorage.getItem('ita-login') !== null;
};

export const GetUserByToken = async (token) => {
    const res = await axios.get(`${API_URL}/Routes.php?target=auth/getbytoken&token=${token}`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    //console.log(res.data.response)
    return res.data?.response;
};

export const GetUsers = async () => {
    const res = await axios.get(`${API_URL}/Routes.php?target=auth/getusers`, 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    //console.log(res.data.response)
    return res.data?.response;
};

export const InviteUser = async (email) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=auth/invite`, JSON.stringify({email}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    console.log(res.data.response)
    return res.data?.response;
};

export const ChangePasswd = async (uid, passwd) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=auth/change-passwd`, JSON.stringify({uid, passwd}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    console.log(res.data.response)
    return res.data?.response;
};

export const GetUser = () => JSON.parse(localStorage.getItem("ita-login"));

export const CreateUser = async (email, nev, passwd) => {
    const res = await axios.post(`${API_URL}/Routes.php?target=auth/create`, JSON.stringify({email, nev: nev, passwd: passwd}), 
    {
        headers: {
            'Content-Type' : 'application/json'
        },
        withCredentials: false,

    });
    
    console.log(res.data);
    return res.data?.response;
}