import React, {useState, useEffect} from 'react'
import { Helmet } from 'react-helmet';
import './Login.css';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import {GetUserByToken, Reg as RegAuth} from '../../Services/Authentication/Auth.inc';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

const Reg = () => {

    const {regid} = useParams();
    
    const [email, setEmail] = useState('E-mail lekérése...');
    const [nev, setNev] = useState('');
    const [passwd, setPasswd] = useState('');
    const [passwd2, setPasswd2] = useState('');

    useEffect(() => {
        GetUserByToken(regid).then((data) => {
            if(data === "" || data === null) { setEmail("Az e-mail címmel már regisztráltak.")}
            setEmail(data);
        });
        return () => {
            setEmail('');
        }
    }, [regid])

    const Submit = (e) => {
        e.preventDefault();

        if(passwd !== passwd2) {
            toast.error("A két jelszó nem egyezik meg!");
            return;
        }
        RegAuth(regid, nev, passwd).then((auth) => {
            if(auth === 'OK') {
                toast.success("Sikeres regisztráció, mostmár bejelentkezhet!");
                
            }
            if(auth === 'doesnt-exist') {
                toast.error("A megadott felhasználó nem létezik.");
            }
            if(auth === 'wrong-passwd') {
                toast.error("Hibás jelszó.")
            }
        });


        
    }; 

    return (
        <>
            <Helmet>
                <title>Bejelentkezés - Smart Communities Platform</title>
            </Helmet>
            <Toaster />
            <Navbar />
            <div id="reg__page">
                
                <div className="container">
                    <div id="login__container">
                        <div className="login__box">
                            <h1>Regisztráció</h1>
                            {/*<div className="error-message">
                                <p>A megadott felhasználó nem létezik.</p>
    </div>*/}
                            <form onSubmit={Submit}>
                                <div className="input__container">
                                    <input disabled className="login_input" value={email} type="email" name="email" id="email" placeholder='E-mail cím' required />
                                    <input className="login_input" value={nev} onChange={(e) => setNev(e.target.value)} type="text" name="nev" id="nev" placeholder='Név' required />
                                    <input className="login_input" value={passwd} onChange={(e) => setPasswd(e.target.value)} type="password" name="passwd" id="passwd" placeholder='Jelszó' required />
                                    <input className="login_input" value={passwd2} onChange={(e) => setPasswd2(e.target.value)} type="password" name="passwd2" id="passwd2" placeholder='Jelszó megerősítése' required />

                                    <button type="submit">Regisztráció</button>
                                </div>
                            </form>
                           
                            
                           
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <Footer />

        </>
    )
}

export default Reg
