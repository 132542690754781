import {useState, useEffect} from "react";
import "./Navbar.css";
import {IoMenuOutline, IoCloseSharp, IoCaretDownOutline} from 'react-icons/io5';
import Logo from '../../Assets/image/projektlogo.jpg';
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";

import {MdLanguage} from "react-icons/md";
import LanguageModal from "./LanguageModal";
import GetTranslation from "../../Language";
import { GetLanguage } from "../../Services/Includes/Language";


const Navbar = () => {

  const [show, setShow] = useState(false);
  const [langShow, setLangShow] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("ita-sc-lang");
    if(!lang) {
        setLangShow(true);
    }
  }, []);
  return (
    <>
    <div id="upperNav">
      <div className="container flex">
        <div className="language__container">

          <a href="#lang" className="language__select" onClick={() => setLangShow(true)}><MdLanguage style={{color: '#fff', marginRight: '5px'}} /> Language</a>
         
        </div>
        <div className="belepes__container">
          <Link to="/belepes" className="belepes"><FaUser style={{marginRight: "5px"}}/> {GetTranslation("login")}</Link>
        </div>
      </div>
    </div>

    <header id="navbar">

      <div className="navbar-container">
      <div id="logo">
          <img src={Logo} alt="Logo" />
      </div>

      <nav className={show ? 'nav show' : 'nav'} id="nav-menu">
        <IoCloseSharp  className="close__nav" onClick={() => setShow(false)} />
        <ul className="nav__list">

            <li className="nav__item">
                <a href="#post" className="nav__link">{GetTranslation("projektek")} <IoCaretDownOutline style={{marginLeft: 1}} /></a>
                <div className="dropdown__menu">
                  <ul>
                    <li ><Link to="/smart-communities-1-0" className="nav__link">Smart Communities 1.0</Link></li>
                    <li ><Link to="/smart-communities-2-0" className="nav__link"> Smart Communities 2.0</Link></li>
                  </ul>
                </div>
            </li>
            {/*<li className="nav__item">
                <a href="#post" className="nav__link">Tevékenységek <IoCaretDownOutline style={{marginLeft: 1}} /></a>

                <div className="dropdown__menu">
                  <ul>
                    <li ><a href="#c1" className="nav__link">Smart Communities 1.0</a></li>
                    <li ><a href="#c2" className="nav__link"> Smart Communities 2.0</a></li>
                  </ul>
                </div>
            </li>*/}
            <li className="nav__item">
                <Link to="/news" className="nav__link">{GetTranslation("hirek")}</Link>
            </li>
            <li className="nav__item">
                <Link to="/docs" className="nav__link">{GetTranslation("dokumentumok")}</Link>
            </li>
            <li className="nav__item">
                <Link to="/match-making" className="nav__link">{GetTranslation("mm-adatbazis")}</Link>
            </li>
            <li className="nav__item">
                <Link to="/presentations" className="nav__link">{GetTranslation("prezentaciok")}</Link>
            </li>
            { GetLanguage() !== "hu" &&
            <li className="nav__item">
                <Link to="/smart-ranking" className="nav__link">{GetTranslation("smartranking")}</Link>
            </li>}
            <li className="nav__item">
                <Link to="/videos" className="nav__link">{GetTranslation("videok")}</Link>
            </li>

        </ul>
      </nav>
      <IoMenuOutline  className="toggle__nav" onClick={() => setShow(true)} />
      </div>
    </header>
    <LanguageModal isShown={langShow} onClose={() => setLangShow(!langShow)} />
    </>
  );
};

export default Navbar;
