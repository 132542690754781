import React, {useEffect, useState} from 'react'
import './Videos.css';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import Helmet from 'react-helmet';
import { IoLayers } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import {GetCourses} from '../../Services/Courses/Course';
import GetCategories, { YoutubeParser } from '../../Services/Includes/categories';
import GetTranslation from '../../Language';
import { GetLanguage } from '../../Services/Includes/Language';

const Videos = () => {

    const [videos, setVideos] = useState([]);
    const [filtered, setFiltered] = useState([]);

    
    
    useEffect(() => {
        GetCourses().then((data) => {
            setVideos(data);
            setFiltered(data);
            //console.log(data)
            
        });

        return () => {
            setVideos([]);
            setFiltered([]);
            
        };
    }, []);

    const [prog, setProg] = useState(false);
    const [bus, setBus] = useState(false);
    const [other, setOther] = useState(false);

    const Filter = () => {
        setFiltered([]);
        if(prog) {
            setFiltered(state => [...state, ...videos.filter(x=>x.category === '1')]);
            //console.log("prog: true")
            //console.log(videos.filter(x=>x.category === '1'));
        }
        if(bus) {
            setFiltered(state => [...state, ...videos.filter(x=>x.category === '2')]);
            //console.log("bus: true")
        }
        if(other) {
            setFiltered(state => [...state, ...videos.filter(x=>x.category === '3')]);
            //console.log("other: true")
        }

        if(!prog && !bus && !other) {
            setFiltered(videos);
        }
    };

    useEffect(() => {
        Filter();
    }, [prog]);
    useEffect(() => {
        Filter();
    }, [bus]);
    useEffect(() => {
        Filter();
    }, [other]);

    /*useEffect(() => {
        console.log("Filtered: ", filtered)
        console.log("Videos: ", videos)
    }, [filtered]);*/





    return (
        <>
        <Helmet>
            <title>{GetTranslation("videok")} - Smart Communities Platform</title>
        </Helmet>
           <Navbar />
           <div id="videos">
                <div className="videos__head">
                    <div className="container">
                        <h1 className='title'>{GetTranslation("videok")}</h1>
                        <div className="mini-nav">
                            <a href="#this" className="page">Kurzusok</a>
                            <div className="page-separator">/</div>
                            <a href="#vidss" className="page active">{GetTranslation("videok")}</a>
                        </div>
                    </div>
                        
                </div>
               <div className="container" id="vidss">
                   <div className="videos__content">
                        <div className="videos__container">
                            {
                                filtered.map((item, i) => (
                                    <div className="vid" key={`vid-${i}`}>
                                        <div className="vid__img">
                                            <img src={`https://img.youtube.com/vi/${YoutubeParser(item.url)}/0.jpg`} alt="Test" />
                                        </div>
                                        <div className="vid__content">
                                            <div className="vid__type">{GetCategories(GetLanguage())[item.category-1]}</div>
                                            <div className="vid__title">{item.title}</div>
                                            <div className="vid__description">{item.intro}</div>
                                            <div className="vid__actions">
                                                <div className="vid__author"><IoLayers className='avatar-icon' /> {item.author}</div>
                                                <div className="vid__view">
                                                    <a href={`/videos/${item.id}`} className="view-btn">{GetTranslation("megtekintes")}</a>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))
                            }
                            

                            
                        </div>
                        <div className="filters__container">
                            {/*<div className="filter">
                                <h3>Szűrő</h3>
                                <p className='filter-status'>Nincs szűrés alkalmazva.</p>
                        </div>*/}
                            <div className="filter">
                                <h3>{GetTranslation("kategoria")}</h3>

                                <div className="categories">
                                   
                                    <Kategoria cat={GetCategories(GetLanguage())[0]} action={() => { setProg(!prog) }} val={prog} />
                                    <Kategoria cat={GetCategories(GetLanguage())[1]} action={() => {setBus(!bus) }} val={bus} />
                                    <Kategoria cat={GetCategories(GetLanguage())[2]} action={() => {setOther(!other) }} val={other} />
                                </div>
                            </div>
                            {/*<div className="filter">
                                <h3>Előadó</h3>
                                <select name="eloadok" id="eloadok" className="eloadok">
                                    <option value="all">Mindenki</option>
                                    <option value="szabozsolt">Szabó Zsolt</option>
                                    <option value="szabozsolt">Instruktor</option>
                                </select>
                        </div>*/}
                        </div>
                   </div>
                    
               </div>
                
               
           </div>
            <Footer />
        </>
    )
}

const Kategoria = ({cat, action, val}) => {
    return (
        <label className="category">
            {cat}
            <input type="checkbox" onChange={action} value={val} />
            <div className="check"></div>
        </label>
    );
}
export default Videos
