import React, {useState, useEffect} from 'react'
import { IoLayers } from 'react-icons/io5';
import { BiDownload } from 'react-icons/bi';
import { useParams } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import Widget from '../Widget/Widget';
import './Video.css';
import {GetCourse} from '../../Services/Courses/Course';
import GetCategories, { YoutubeParser } from '../../Services/Includes/categories';
import GetTranslation from '../../Language';
import { GetLanguage } from '../../Services/Includes/Language';

const Video = () => {

    const { VideoID } = useParams();

    const [video, setVideo] = useState([]);
    const [url, setUrl] = useState('');
    const [files, setFiles] = useState([]);

    useEffect(() => {

        GetCourse(VideoID).then((data) => {

            setUrl(YoutubeParser(data.course[0].url))
            if(data.course[0])
            {
                setVideo(data.course[0]);
                setFiles(data.files);
            }
                
        });
        return () => {
            setVideo([]);
            setUrl('');
            setFiles([]);
        };
    }, [VideoID]);

    

    return (
        <>
            <Navbar />
            <div id="video">
                <div className="video__head">
                    <div className="container">
                        <div className="video__type">{GetCategories(GetLanguage())[video?.category - 1]}</div>
                        <h1 className="video__title">{video?.title}</h1>
                        <div className="video__description">{video?.intro}</div>
                        <div className="video__infos">
                            <div className="info"><IoLayers className='info-icon' style={{color: '#066AC9'}} />{video?.author}</div>
                            {/*<div className="info"><IoTimeSharp className='info-icon'/> 00:45:00</div>*/}
                        </div>
                    </div>
                    

                </div>
                <div className="container">
                    <div className="video__content">
                        <div className="video__section">
                            <div className="video__header">
                                <h3>{GetTranslation("videok")}</h3>
                            </div>
                            <div className="vid__content">
                                <iframe className='iframe-video' src={`https://www.youtube.com/embed/${url}?vq=hd720&cc_load_policy=1&cc_lang_pref=sk`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                <small>A videóanyag kiszolgálója a YouTube.</small>
                                <div className="vid__text">
                                    <h3>{GetTranslation("leiras")}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: video?.content}}></div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div className="video__widget">
                            <Widget title={GetTranslation("csatolmany")}>
                                <div className="csatolmanyok">
                                    {files.map((item, i) => (
                                        <div className="csatolmany" key={`attachment-${i}`}>
                                            <p style={{wordBreak: 'break-word'}}><a href={item.url} target="_blank" rel="noreferrer" className="filename">{item.filename}</a></p>
                                            <a href={item.url} download={item.url} className="btn bg-primary"> {GetTranslation("letoltes")} <BiDownload style={{marginLeft: "5px"}} className='btn-icon' /></a>
                                        </div>
                                    ))}
                                    
                                </div>
                            </Widget>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
        </>
    )
}

export default Video
