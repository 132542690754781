import React, {useState} from 'react'
import { Helmet } from 'react-helmet';
import './Login.css';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';

import {Login as LoginAuth} from '../../Services/Authentication/Auth.inc';
import toast, { Toaster } from 'react-hot-toast';

const Login = () => {

    const [email, setEmail] = useState('');
    const [passwd, setPasswd] = useState('');

    const Submit = (e) => {
        e.preventDefault();

        if(email && passwd) {
            LoginAuth(email, passwd).then((auth) => {
                if(auth[0]?.nev) {
                    toast.success("Sikeres bejelentkezés!");
                    window.location.reload();
                }
                if(auth === 'doesnt-exist') {
                    toast.error("A megadott felhasználó nem létezik.");
                }
                if(auth === 'wrong-passwd') {
                    toast.error("Hibás jelszó.")
                }
            });
        }

        
    }; 

    return (
        <>
            <Helmet>
                <title>Bejelentkezés - Smart Communities Platform</title>
            </Helmet>
            <Toaster />
            <Navbar />
            <div id="login__page">
                
                <div className="container">
                    <div id="login__container">
                        <div className="login__box">
                            <h1>Bejelentkezés</h1>
                            {/*<div className="error-message">
                                <p>A megadott felhasználó nem létezik.</p>
    </div>*/}
                            <form onSubmit={Submit}>
                                <div className="input__container">
                                    <input className="login_input" value={email} onChange={(e) => setEmail(e.target.value)} type="email" name="email" id="email" placeholder='E-mail cím' required />
                                    <input className="login_input" value={passwd} onChange={(e) => setPasswd(e.target.value)} type="password" name="passwd" id="passwd" placeholder='Jelszó' required />

                                    <button type="submit">Belépés</button>
                                </div>
                            </form>
                           
                            
                           
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <Footer />

        </>
    )
}

export default Login
