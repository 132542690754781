import React, {useState, useRef, useEffect} from 'react';
import { IoCheckmarkCircle, IoCloudUploadOutline } from 'react-icons/io5';
import { GetCourse, ModifyCourse, DeleteFile, UploadFile } from '../../Services/Courses/Course';
import '../Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';
import './Course.css';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import { API_URL } from '../../Services/Includes/backend.config';

const EditCourse = () => {

    const {id} = useParams();

    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [intro, setIntro] = useState('');
    const [category, setCategory] = useState(1);
    const [lang, setLang] = useState(1);
    const [files, setFiles] = useState([]);

    const fileUploader = useRef();
    const editorRef = useRef(null);

    const [defaultEditor, setDefaultEditor] = useState("");

    const SetFile = async (e) => {

        if(title.length === 0) {
            toast.error("A videó címét be kell állítani, mielőtt fájlt feltöltene.");
            return;
        }
        

        const f = e.target.files;
        console.log(f[0])
        let formData = null;
        for (let i = 0; i < f.length; i++) {

            formData = new FormData();
            formData.append("file", f[i]);

            toast.promise(UploadFile(formData, title).then((data) => setFiles(state => [...state, data])), {
                loading: `${f[i].name} feltöltése folyamatban...`,
                success: `${f[i].name} feltöltve.`
                

            });
            
        }
        
    };

    const DeleteF = (url) => {
        const item = files.find(x=>x.url === url);

        if(window.confirm("Biztos, hogy törli a kiválasztott csatolmányt?")) {
            toast.promise(
                DeleteFile(item, id).then((data) => {
                    if(data === "OK") {
                        setFiles(state => state.filter(x=>x.url !== url));
                    }
                }),
                {
                    loading: 'Fájl törlése folyamatban...',
                    success: 'A kijelölt fájl törlésre került.'
                }
            );
        }
        console.log("Item: ",item);
    };

    const Submit = (e) => {

        e?.preventDefault();

        toast.promise(ModifyCourse(title, url, intro, category, lang, id, files, editorRef.current?.getContent()),
        {
            loading: 'Kurzus módosítása...',
            success: 'Sikeres módosítás!',
            error: (err) => `Nem sikerült módosítani. ${err}`
        });
        

    };

    useEffect(() => {
        toast.promise(GetCourse(id).then((data) => {
            console.log(data);
            let d = data.course[0];
            setTitle(d?.title);
            setUrl(d?.url);
            setIntro(d?.intro);
            setCategory(d.category);
            setLang(d?.lang);
            setFiles(data.files);
            setDefaultEditor(d?.content);
        }), {
            loading: 'Kurzus betöltése...',
            success: 'Kurzus betöltve.'
        })
    }, [id]);


    return (
        <div className='dashboard__page'>
            <Sidebar />
            <div className="dashboard__content" id="home">
                <Toaster />
                <h1>Kurzus szerkesztése</h1>

                <div className="card">
                    <div className="card__head">
                        <h3>Kurzus szerkesztése</h3>
                    </div>
                    <div className="card__body">
                        <form className='mb-4'>
                            <div className="col-6">
                                <input type="text" className="form-control mb-2 form-sm" placeholder='Cím' value={title} onChange={(event) => setTitle(event.target.value)} />
                                <input type="text" className="form-control form-sm mb-2" placeholder='Videó URL' value={url} onChange={(event) => setUrl(event.target.value)} />
                            </div>         
                            
                            <input type="text" className="form-control form-sm mb-2" placeholder='Rövid bevezető' value={intro} onChange={(event) => setIntro(event.target.value)} />
                            <div className="col-6">
                                <select name="category" className="select form-sm" value={category} onChange={(e) => setCategory(e.target.value)}>
                                    <option value="1">Programozás</option>
                                    <option value="2">Üzleti intelligencia</option>
                                    <option value="3">Egyéb</option>
                                </select>
                                <select name="language" className="select form-sm" value={lang} onChange={(event) => setLang(event.target.value)}>
                                    <option value="hu">Magyar</option>
                                    <option value="en">Angol</option>
                                    <option value="sk">Szlovák</option>
                                </select>
                            </div>
                        </form>
                       
                        <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={defaultEditor}
                                apiKey='em5jpi5sf02sb0fa7qcqtpi4meikooepovwv0jdcpyd94lon'
                                
                                init={{language:"hu_HU", height: 500, entity_encoding: 'raw', 
                                    plugins: 'fullscreen wordcount autosave autoresize hr table emoticons preview image imagetools',
                                    toolbar:    'insertfile undo redo | formatselect | ' +
                                                'bold italic backcolor hr | alignleft aligncenter ' +
                                                'alignright alignjustify | table | bullist numlist outdent indent | ' +
                                                'removeformat | help | preview image',
                                    file_picker_types: "file image media",
                                    placeholder: "Leírás (üresen hagyható)",
                                    images_upload_handler: async function(
                                        blobInfo,
                                        success,
                                        failure
                                        ) {
                                            let imageFile = new FormData();
                                            imageFile.append("files[]", blobInfo.blob());

                                            axios.post(API_URL + `/functions/fileupload.php`, imageFile, {headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }}).then((data) => success(data.data.response));
                                        }

                                }}
                                

                            />
                       
                        <h4 className='mb-2' style={{marginTop: '1em'}}>Csatolmányok</h4>
                        <input type="file" multiple ref={fileUploader} style={{display: 'none'}} onChange={SetFile} />
                        <div className="attachments">
                            {files.map((item, i) => (
                                <div className="attachment" key={`attachment-${i}`}>
                                    <a href={item.url} target="_blank" rel="noreferrer" className="filename">{item.filename}</a>
                                    <button className="btn bg-danger" onClick={() => DeleteF(item.url)}>Törlés</button>
                                </div>
                            ))}
                            
                        </div>
                        <button className='btn bg-success mb-4' onClick={() => fileUploader.current?.click()}><IoCloudUploadOutline className='btn-icon' /> Feltöltés</button>

                        <button className="btn bg-warning" onClick={Submit}><IoCheckmarkCircle className='btn-icon' /> Módosítás</button>
                    </div>

                  
                </div>
            </div>
        </div>
    )
}

export default EditCourse
